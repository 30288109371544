import { ComponentID, AppComponent } from '@/types';
import { compact2array } from 'pinyin/lib/util';

export const generateComponentID: () => ComponentID = () => {
    return Math.floor(100000 + Math.random() * 100000);
};

export const generateRequestID: () => ComponentID = () => {
    return Math.floor(100000 + Math.random() * Math.floor(Math.random() * Date.now()))
};

export const compareComponents: (comp1: AppComponent, comp2: AppComponent) => boolean = (comp1: AppComponent, comp2: AppComponent) => {
    if(!comp1.id) return false;
    if(!comp2.id) return false;
    return comp1.id == comp2.id;
};

export const formatToUTC: (local_date: string) => Date = (local_date: string) => {
    const date_components: Array<string> = local_date.split("-");
    const date_value: Date = new Date(Date.UTC(parseInt(date_components[0]), parseInt(date_components[1])-1, parseInt(date_components[2]), 0, 0, 0, 0));
    return date_value;
};