

















































































import { Component, Vue /*, Prop */ } from 'vue-property-decorator';
import {TYPES} from '@/ioc/types';
import {Authenticator, ModuleProvider, MessageDispatcher, NavRouter} from '@/services';
import {lazyInject} from '@/ioc/inversify.config';
import {AppModule, AuthStatus, SessionStatus, AppStage, ModuleLifecycleEvent, Navigatable} from '@/types';
import {ROUTER_EVENT, SHENGCI_MODULES, VUE_LIFECYCLE_EVENT} from '@/enums';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import StickyNotificationsComponent from '@/components/StickyNotificationsComponent.vue';
import ToastsComponent from '@/components/ToastsComponent.vue';
import AppMain from '@/AppMain';
import { combineLatest, Subscription } from 'rxjs';
import { bufferWhen, filter, mapTo, take } from 'rxjs/operators';
import { AppNotification } from './classes/AppNotification';
//import router from './router';
import { log } from '@/helpers/logger';

@Component({
  components: {
    AsyncButton, StickyNotificationsComponent, ToastsComponent
  }
})
export default class App extends Vue
{
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.MESSAGEDISPATCHER_INSTANCE)
  private message_dispatcher!: MessageDispatcher;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  public drawer: boolean = false;
  public navigation_index: number = 0;
  private app_modules: Array<AppModule> = Array<AppModule>();
  private auth_status: AuthStatus = "pending";
  private session_status: SessionStatus = "pending";
  private next_module_is_ready: boolean = false;
  private app_stage: AppStage = "loading";

  created(): void
  {
    this.auth_status = this.authenticator.auth_status;
    this.session_status = this.authenticator.session_status;
    this.app_modules = this.module_provider.getAccessibleModules();
    //the module we will redirect to when we finish authentication
    let next_module: Navigatable = SHENGCI_MODULES.NONE;

    //if new session cannot be created
     this.authenticator.session_events$.subscribe({
       next: (session_status: SessionStatus) => {
         this.session_status = session_status;
         if(AppMain.getAppStage() == "authenticated" && this.session_status == 'invalid') {
            this.message_dispatcher.pushNotification(new AppNotification(
                "Server is unresponsive. Are you offline?",
                "sticky",
              ));
            /*
            this.message_dispatcher.pushNotification({
                message: "Server is unresponsive. Are you offline?",
                timeout: -1,
                type: "offline",
                is_open: true,
                id: Math.random() * 100 +  (new Date(Date.now())).getTime(),
            });
            */
         }
       },
     });

     combineLatest(this.authenticator.auth_events$, this.nav_router.router_ready$).subscribe({  
        next: (auth_statuses: [AuthStatus, ROUTER_EVENT]) => {
          this.auth_status = auth_statuses[0];
          next_module = SHENGCI_MODULES.NONE;
          if(this.auth_status == 'logged in') {
            if(this.nav_router.isCurrentlyAt(SHENGCI_MODULES.LOGIN) || this.nav_router.isCurrentlyRootPath()) {
              this.next_module_is_ready = false;
              next_module = this.nav_router.navigateToIfPossible(SHENGCI_MODULES.VOCABULARY, SHENGCI_MODULES.HOME);
            } else {
              this.setAppStage("authenticated");
            }
            this.drawer = false;
          } else if(this.auth_status == 'logged out') {
              if(AppMain.getAppStage() !== "authenticated") {
                if(!this.nav_router.isCurrentlyAt(SHENGCI_MODULES.LOGIN)) {
                  this.next_module_is_ready = false;
                  next_module = this.nav_router.navigateToIfPossible(SHENGCI_MODULES.LOGIN);
                } else {
                  this.setAppStage("unauthenticated");
                }
              } else {
                 if(this.nav_router.isCurrentlyAt(SHENGCI_MODULES.LOGOUT)) {
                    this.next_module_is_ready = false;
                    next_module = this.nav_router.navigateToIfPossible(SHENGCI_MODULES.LOGIN);
                 } else if(!this.nav_router.isCurrentlyAt(SHENGCI_MODULES.LOGIN)) {
                  //constructor(message: string, type: NotificationType, action?: NotificationAction, timeout?: number, closer?: Subject<boolean>) {
                  this.message_dispatcher.pushNotification(new AppNotification(
                    "You have been logged out.",
                    "sticky",
                    { action_message: "Log in", action_call: () => this.nav_router.navigateToIfPossible(SHENGCI_MODULES.LOGIN) },
                    this.nav_router.module_lifecycle_events$.pipe(
                      filter((module_lc_event: ModuleLifecycleEvent) => {
                        if(module_lc_event.event == VUE_LIFECYCLE_EVENT.MOUNTED && module_lc_event.module == SHENGCI_MODULES.LOGIN) {
                          return true;
                        }
                        return false;
                      }),
                      take(1),
                      mapTo(true)
                    ),
                    "logged out"
                  ));
                  /*
                    this.message_dispatcher.pushNotification({
                      message: "You have been logged out.",
                      action : {
                        message: "Log in",
                        route: SHENGCI_MODULES.LOGIN
                      },
                      type: "logged out",
                      timeout: -1,
                      is_open: true,
                      id: Math.random() * 100 +  (new Date(Date.now())).getTime(),
                    });
                    */
                 }
              }
          }
          if(next_module !== SHENGCI_MODULES.NONE) {
            let next_module_events$: Subscription = this.nav_router.module_lifecycle_events$.subscribe({
              next: (module_lc_event: ModuleLifecycleEvent) => {
                if(module_lc_event.event == VUE_LIFECYCLE_EVENT.MOUNTED && this.nav_router.navigatablesAreSame(next_module, module_lc_event.module)) {
                  this.next_module_is_ready = true;
                  this.setAppStage(this.authenticator.isAuthenticated() ? "authenticated" : "unauthenticated");
                  next_module_events$.unsubscribe();
                }
              }
            });
          }
        }
    });

    //If we're in "app", but arrive at login then retry session
    let any_module_events$: Subscription = this.nav_router.module_lifecycle_events$.subscribe({
        next: (module_lc_event: ModuleLifecycleEvent) => {
          if(module_lc_event.event == VUE_LIFECYCLE_EVENT.MOUNTED && this.nav_router.navigatablesAreSame(SHENGCI_MODULES.LOGIN, module_lc_event.module)) {
            if(this.authenticator.session_status == "invalid") {
              this.resetAppStage();
            }
          }
        }
      });
  }

  resetAppStage(): void {
    this.next_module_is_ready = false;
    this.app_stage = AppMain.setAppStage("loading");
    this.authenticator.regenerateSession();
  }

  setAppStage(new_app_stage: AppStage): void {
    this.app_stage = AppMain.setAppStage(new_app_stage);
    this.app_modules = this.module_provider.getAccessibleModules();
  }

  
}
