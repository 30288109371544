import {injectable} from 'inversify';
import {ClientKeyedStorage} from '@/services/ClientKeyedStorage/ClientKeyedStorage.inf';
import {KeyDoesNotExist} from '@/exceptions';
import {Nullable} from '@/types';
import {STORAGE_KEY_NAMES} from '@/enums';

@injectable()
export class ClientKeyedStorageImp implements ClientKeyedStorage
{
    private client_store: Storage;

    constructor()
    {
        this.client_store = window.localStorage;
    }

    hasItem(key_name: STORAGE_KEY_NAMES): boolean {
        return null !== this.client_store.getItem(key_name);
    }

    getItem(key_name: STORAGE_KEY_NAMES): string {
        const item: Nullable<string> = this.client_store.getItem(key_name);
        if(item === null) {
            throw new KeyDoesNotExist(`ClientKeyedStorage does not contain the item: ${key_name}`);
        }
        return item as string;
    }

    setItem(key_name: STORAGE_KEY_NAMES, key_value: string): void {
        this.client_store.setItem(key_name, key_value);
    }

    removeItem(key_name: STORAGE_KEY_NAMES): void {
        if(!this.hasItem(key_name)) {
            throw new KeyDoesNotExist(`ClientKeyedStorage does not contain the item: ${key_name}`);
        }
        this.client_store.removeItem(key_name);
    }

    clearItems(): void {
        this.client_store.clearItems();
    }
}