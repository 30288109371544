import {Stringable, Role, Nullable, Validatable} from '@/types';
//import {FunctionParameterBadType, FunctionParameterEmpty, FunctionParameterBadValue} from '@/exceptions';
import Ajv, {JSONSchemaType} from "ajv";
import addFormats from "ajv-formats";
import {ValidationFailed} from '@/exceptions';

export interface UserParams {
    id: number;
    name: string;
    email: string;
    roles: string[];
    created_at: string;
    updated_at: string;
}

export const user_params_schema: JSONSchemaType<UserParams> = {
    type: "object",
    properties: {
        id: {type: "integer", minimum: 1, nullable: true},
        name: {type: "string", nullable: true},
        email: {type: "string", nullable: true},
        roles: {
            type: "array", nullable: true, 
            items: {
                type: "string",
                enum: ["ADMIN", "USER", "PREMIUM", "GUEST"]
            },
        },
        created_at: {type: "string", format: "date-time", nullable: true},
        updated_at: {type: "string", format: "date-time", nullable: true},
    },
    required: ["id", "name", "email", "roles", "created_at", "updated_at"],
    additionalProperties: false
}

const ajv = new Ajv();
addFormats(ajv);

export const user_params_validator = ajv.compile<UserParams>(user_params_schema)

export class User implements Stringable, Validatable
{
    id: number= 0;
    name: string = "guest";
    email: string = "";
    roles: Array<Role> = User.getDefaultRoles();
    created_at: Date = new Date(Date.now());
    updated_at: Date = new Date(Date.now());

    constructor(id: Nullable<number>, name: Nullable<string>, email: Nullable<string>, roles: Nullable<Array<Role>>, created_at: Nullable<Date>, updated_at: Nullable<Date>) {
        if(id) this.id = id; 
        if(name) this.name = name;
        if(email) this.email = email; 
        if(roles) this.roles = roles; 
        if(created_at) this.created_at = created_at;
        if(updated_at) this.updated_at = updated_at;
    }

    static getDefaultRoles(): Array<Role> {
        return ["GUEST"] as Array<Role>;
    }

    static fromJSON(user_params: UserParams): User {
        if(!user_params_validator(user_params)) {
            throw new ValidationFailed(user_params_validator.errors);
        }
        let user_roles: Array<Role> = new Array<Role>();
        if(user_params.roles.length > 0) {
            user_params.roles.forEach( (role: string) => {user_roles.push(role as Role)});
        } else {
            user_roles = User.getDefaultRoles();
        }
        return new User(user_params.id, user_params.name, user_params.email, user_roles, new Date(user_params.created_at), new Date(user_params.updated_at));
    }

    asString(): string {
        return JSON.stringify(this);
    }
}