import {AppModule} from '@/types';
import {SHENGCI_MODULES} from '@/enums';

const login_module: AppModule = {
    name: 'login',
    title: 'Login',
    icon: 'mdi-login',
    route: {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginView.vue')
    },
    required_roles: ["GUEST"],
};

const logout_module: AppModule = {
    name: 'logout',
    title: 'Logout',
    icon: 'mdi-logout',
    route:  {
        path: '/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "logout" */ '@/views/LogoutView.vue')
      },
    required_roles: ["USER"],
};

const tags_module: AppModule = {
    name: 'tags',
    title: 'Tags',
    icon: 'mdi-tag-multiple',
    route:  {
        path: '/tags',
        name: 'tags',
        component: () => import(/* webpackChunkName: "tags" */ '@/views/TagsView.vue')
      },
    required_roles: ["USER"],
};

const home_module: AppModule = {
    name: 'home',
    title: 'Home',
    icon: 'mdi-home',
    route:  {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/HomeView.vue')
      },
    required_roles: ["USER"],
};


const vocabulary_module: AppModule = {
    name: 'vocabulary',
    title: 'Vocabulary',
    icon: 'mdi-locker',
    route:  {
        path: '/vocabulary',
        name: 'vocabulary',
        component: () => { 
            return import(/* webpackChunkName: "vocabulary" */ '@/views/VocabularyView.vue');
        }
      },
    required_roles: ["USER"],

};

const liebiao_module: AppModule = {
    name: 'liebiaos',
    title: 'Lists',
    icon: 'mdi-format-list-numbered',
    route:  {
        path: '/lists/:list_name?',
        name: 'liebiaos',
        component: () => { 
            return import(/* webpackChunkName: "liebiao" */ '@/views/LiebiaoView.vue');
        },
      },
    required_roles: ["USER"],
};

const quiz_module: AppModule = {
    name: 'quiz',
    title: 'Quiz',
    icon: 'mdi-school',
    route:  {
        path: '/quiz/:quiz_id?',
        name: 'quiz',
        component: () => { 
            return import(/* webpackChunkName: "quiz" */ '@/views/QuizView.vue');
        },
      },
    required_roles: ["USER"],
};

const settings_module: AppModule = {
    name: 'settings',
    title: 'Settings',
    icon: 'mdi-cog-outline',
    route:  {
        path: '/settings',
        name: 'settings',
        component: () => { 
            return import(/* webpackChunkName: "settings" */ '@/views/VocabularySettings.vue');
        },
      },
    required_roles: ["USER"],
};

const summary_module: AppModule = {
    name: 'summary',
    title: 'Summary',
    icon: 'mdi-chart-pie',
    route:  {
        path: '/summary',
        name: 'summary',
        component: () => { 
            return import(/* webpackChunkName: "summary" */ '@/views/VocabularySummary.vue');
        },
      },
    required_roles: ["USER"],
};

const achievements_module: AppModule = {
    name: 'achievements',
    title: 'Achievements',
    icon: 'mdi-trophy',
    route:  {
        path: '/achievements',
        name: 'achievements',
        component: () => { 
            return import(/* webpackChunkName: "achievements" */ '@/views/AchievementsView.vue');
        },
      },
    required_roles: ["USER"],
};

const app_modules = new Map<SHENGCI_MODULES, AppModule>([
    [SHENGCI_MODULES.LOGIN, login_module],
    [SHENGCI_MODULES.LOGOUT, logout_module],
    [SHENGCI_MODULES.VOCABULARY, vocabulary_module],
    [SHENGCI_MODULES.HOME, home_module],
    [SHENGCI_MODULES.TAGS, tags_module],
    [SHENGCI_MODULES.LIEBIAO, liebiao_module],
    [SHENGCI_MODULES.QUIZ, quiz_module],
    [SHENGCI_MODULES.SUMMARY, summary_module],
    [SHENGCI_MODULES.SETTINGS, settings_module],
    [SHENGCI_MODULES.ACHIEVEMENTS, achievements_module],
]);

export default app_modules;