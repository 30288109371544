import {injectable} from 'inversify';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import {FileResourceService} from '@/services/FileResourceService/FileResourceService.inf'
import {Observable, Subscription, from, timer, combineLatest, defer, throwError, Subject, ReplaySubject, of} from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share} from 'rxjs/operators';
import {genericRetryStrategy} from '@/helpers/genericRetryStrategy';
import {WAIT_TIMES, WAIT_SCALING} from '@/enums';
import { AppURL } from '@/classes/AppURL';
import { FileResourceNotFound } from '@/exceptions';

@injectable()
export class FileResourceServiceImp implements FileResourceService
{
  fetchFileResource(url: AppURL): Promise<File> {
    return new Promise<File>((resolve, reject)=>{
      fetch(url.getFullURL())
        .then((response: Response) => {
          if(response.ok) {
            response.blob().then((file_blob: Blob) => {
              resolve(new File([file_blob], url.getFileName(), {type: file_blob.type}));
            });
          } else {
            throwError(new FileResourceNotFound(url.getFullURL()));
            reject();
          }
        });
    });
    
  }
}