export const TYPES = {
    //MUSIC_SERVICE: Symbol('MUSIC_SERVICE'),
    //MUSIXMATCH_BASE_URL: Symbol('MUSIXMATCH_BASE_URL'),
    HANASU_INSTANCE: Symbol('HANASU_INSTANCE'),
    AUTHENTICATOR_INSTANCE: Symbol('AUTHENTICATOR_INSTANCE'),
    MODULE_PROVIDER_INSTANCE: Symbol('MODULE_PROVIDER_INSTANCE'),
    CLIENT_KEYEDSTORAGE_INSTANCE: Symbol('CLIENT_KEYEDSTORAGE_INSTANCE'),
    COOKIE_MONSTER_INSTANCE: Symbol('COOKIE_MONSTER_INSTANCE'),
    MESSAGEDISPATCHER_INSTANCE: Symbol('MESSAGEDISPATCHER_INSTANCE'),
    NAVROUTER_INSTANCE: Symbol('NAVROUTER_INSTANCE'),
    TAGBOSS_INSTANCE: Symbol('TAGBOSS_INSTANCE'),
    VOCABULARYSERVICE_INSTANCE: Symbol('VOCABULARYSERVICE_INSTANCE'),
    AUDIOMANAGER_INSTANCE: Symbol('AUDIOMANAGER_INSTANCE'),
    FILE_RESOURCE_SERVICE_INSTANCE: Symbol('FILE_RESOURCE_SERVICE_INSTANCE'),
    LIEBIAO_MANAGER_INSTANCE: Symbol('LIEBIAO_MANAGER_INSTANCE'),
    QUIZ_SERVICE_INSTANCE: Symbol('QUIZ_SERVICE_INSTANCE'),
  };
  