import { inject, injectable } from 'inversify';
import {TYPES} from '@/ioc/types';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import {from, Observable, throwError} from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share} from 'rxjs/operators';
import { TagBoss } from '@/services/TagBoss/TagBoss.inf';
import { Hanasu } from '@/services';
import { TagRequest, TagResult, Tag, SimpleValidationResult } from '@/types';
import Ajv, {JSONSchemaType} from "ajv";
import addFormats from "ajv-formats";
import {ValidationFailed} from '@/exceptions';

type TagData = {
    id: number;
    name: string;
    description?: string;
    created_at?: string;
    entry_count?: number;
}

type TagResultsData = {
    request_id: number,
    page?: number,
    total: number,
    tags: Array<TagData>
}

export const tag_params_schema: JSONSchemaType<TagResultsData> = {
    $id: "shengci.com/schemas/tag_params.json",
    type: "object",
    properties: {
        request_id: {type: "integer", minimum: 1},
        page: {type: "integer", minimum: 0, nullable: true},
        total: {type: "integer", minimum: 0},
        tags: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    id: {type: "integer", minimum: 1},
                    name: {type: "string"},
                    description: {type: "string", nullable: true},
                    created_at: {type: "string", format: "date-time", nullable: true},
                    entry_count: {type: "integer", nullable: true},
                },
                required: ["id", "name"],
                additionalProperties: false
            }
        }
    },
    required: ["request_id", "tags", "total"],
    additionalProperties: false
}

const ajv = new Ajv();
addFormats(ajv);

export const tag_params_validator = ajv.compile<TagResultsData>(tag_params_schema)

@injectable()
export class TagBossImp implements TagBoss
{
    private readonly hanasu: Hanasu;
    private readonly tags_url: string = "api/tags/index";
    private readonly validate_tag_url: string = "api/tags/validate";
    
    public constructor(
        @inject(TYPES.HANASU_INSTANCE) _hanasu: Hanasu,
    ) {
        this.hanasu = _hanasu;
    }

    validateTag(tag_request: TagRequest): Observable<SimpleValidationResult> {
        return this.hanasu.postBuffered<TagRequest, SimpleValidationResult>(this.validate_tag_url, tag_request).pipe(
            map( (response: AxiosResponse<SimpleValidationResult>) => {
                return response.data;
            }),
            catchError( (error) => {
                console.log('validate tag throw error!');
                return throwError(error);
            })
        );
    }

    requestTags(tag_request: TagRequest): Observable<TagResult> {
        return this.hanasu.postBuffered<TagRequest, TagResultsData>(this.tags_url, tag_request).pipe(
            map( (response: AxiosResponse<TagResultsData>) => {
                if(process.env.VUE_APP_DEBUG) {
                    if(!tag_params_validator(response.data)) {
                        throw new ValidationFailed(tag_params_validator.errors);
                    }
                }
                const tags: Array<Tag> = [];
                response.data.tags.forEach((tag_data: TagData) => {
                    tags.push(this.makeTag(tag_data));
                });
                const tag_results: TagResult = {
                    request_id: tag_request?.request_id ? tag_request.request_id : 0,
                    tags:tags,
                    total: response.data.total,
                };
                if(response.data.page) {
                    tag_results.page = response.data.page;
                }
                return tag_results;
            })
        );
    }

    makeTag(tag_data: TagData): Tag {
        return {
            id: tag_data.id,
            name: tag_data.name,
            description: tag_data.description ? tag_data.description : "",
            created_at: tag_data.created_at ? new Date(tag_data.created_at) : new Date(Date.now()),
            entry_count: tag_data.entry_count ? tag_data.entry_count : 0
        }
    }
}
