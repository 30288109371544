import {inject, injectable} from 'inversify';
import {TYPES} from '@/ioc/types';
import { ModuleProvider } from '@/services';
import app_modules_imported from '@/AppModules';
import {Authenticator} from '@/services';
import difference from 'lodash/difference';
import {AppModule, Role, Nullable, UserInfo} from '@/types';
import {SHENGCI_MODULES} from '@/enums';
import {ModuleNotFound} from '@/exceptions';
import { Route } from 'vue-router';
import { User } from '@/classes/User';

@injectable()
export class ModuleProviderImp implements ModuleProvider
{
    private app_modules: Map<SHENGCI_MODULES, AppModule>;

    private authenticator: Authenticator;

    public constructor(
        @inject(TYPES.AUTHENTICATOR_INSTANCE) _authenticator: Authenticator,
    ) {
        this.authenticator = _authenticator;
        this.app_modules = app_modules_imported;
    }

    /** 
     * @throws {ModuleNotFound}
     */
    getModuleByRouteName(route_name: Nullable<string>): AppModule {
        if(!route_name) {
            throw new ModuleNotFound(route_name ?? 'no route name');
        }
        const app_modules_itr: IterableIterator<[SHENGCI_MODULES, AppModule]> = this.app_modules.entries();
        let module_name: SHENGCI_MODULES = SHENGCI_MODULES.NONE; 
        if(route_name) {
            for (const app_module_entry of app_modules_itr) {
                if(app_module_entry[1].route.name == route_name) {
                    module_name = app_module_entry[0];
                    break;
                }
            }
        }
        if(module_name == SHENGCI_MODULES.NONE) {
            throw new ModuleNotFound(route_name ?? 'no route name');
        }
        return this.getModule(module_name);
    }

    /** 
     * @throws {ModuleNotFound}
     */
     getModuleByRoute(route: Route): AppModule {
        return this.getModuleByRouteName(route.name ?? null);
    }

    /** 
     * @throws {ModuleNotFound}
     */
    getModule(name: SHENGCI_MODULES): AppModule
    {
        const _module: AppModule | undefined = this.app_modules.get(name);
        if(!_module) {
            throw new ModuleNotFound(name);
        }
        return _module as AppModule;
    }

    /** 
     * @throws {ModuleNotFound}
     */
    canAccessModule(module: AppModule | SHENGCI_MODULES): boolean
    {
        let _module: AppModule;
        if(this.isAppModule(module)) {
            _module = module;
        } else {
            _module = this.getModule(module);
        }

        const local_user: Nullable<UserInfo> = this.authenticator.getLocalUserInfo();
        let user_roles: Array<Role> = new Array<Role>();
        if(!local_user) {
            user_roles = User.getDefaultRoles();
        } else {
            user_roles = local_user.user.roles;
        }
        if(difference(_module.required_roles, user_roles).length > 0) {
            return false;
        }
        return true;
    }

    getAccessibleModules(): AppModule[]
    {
        return [...this.app_modules.values()].filter(module => this.canAccessModule(module));
    }

    isAppModule(module: AppModule | SHENGCI_MODULES): module is AppModule {
        return (module as AppModule).title !== undefined;
      }
}