import 'reflect-metadata';
import ShengciApp from '@/AppMain';
import { InvalidAppConfiguration } from '@/exceptions';


if(!process.env.VUE_APP_URL || process.env.VUE_APP_URL.length <= 0) {
    throw new InvalidAppConfiguration("VUE_APP_URL has not been set. Are you missing an .env file? Or the wrong build mode was specified?");
}

//console.log("hello there!");
//console.log(process.env);

ShengciApp.start();