import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import {AppModule} from '@/types';
import app_modules_imported from '@/AppModules';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [];
app_modules_imported.forEach( (module: AppModule) => {
  routes.push(module.route);
});

const router = new VueRouter({
  mode: 'history',
  base: `${process.env.BASE_URL}`,
  routes
});

//TOOD: before any route is accessed if user is not authenticated, redirect to home

export default router;
