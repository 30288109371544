import { inject, injectable } from 'inversify';
import { NavRouter } from '@/services/NavRouter/NavRouter.inf'
import { AppModule, Navigatable, ModuleLifecycleEvent, NavFromTo } from '@/types';
import { TYPES } from '@/ioc/types';
import { ModuleProvider } from '@/services';
import { NoAccessibleModule } from '@/exceptions';
import Vue from 'vue';
import { SHENGCI_MODULES, ROUTER_EVENT } from '@/enums';
import { Subject, ReplaySubject } from 'rxjs';
import app_modules_imported from '@/AppModules';
import { Route, RouteConfig, Location } from 'vue-router';

@injectable()
export class NavRouterImp implements NavRouter
{
  private vue_instance!: Vue;
  private module_provider!: ModuleProvider;
  private router_is_ready: boolean = false;
  public readonly root_path: string = "/";
  public readonly module_lifecycle_events$: Subject<ModuleLifecycleEvent> = new Subject<ModuleLifecycleEvent>();
  public readonly router_ready$: ReplaySubject<ROUTER_EVENT> = new ReplaySubject<ROUTER_EVENT>(1);
  public readonly nav_event$: Subject<NavFromTo> = new Subject<NavFromTo>();

  public constructor(
    @inject(TYPES.MODULE_PROVIDER_INSTANCE) _module_provider: ModuleProvider,
  ) {
      this.module_provider = _module_provider;
  }

  /*
  REFACTOR NOTE:
    1) Use vue-router's Location type to formulate navigation actions
  */
  onRouterReady(): void {
    if(!this.router_is_ready) {
      this.router_is_ready = true;
      this.router_ready$.next(ROUTER_EVENT.NAVIGATION_SUCCESS);
    }
  }

  pushModuleLifecycleEvent(module_event: ModuleLifecycleEvent): void {
    this.module_lifecycle_events$.next(module_event);
  }

  setVueInstance(_vue_instance: Vue): void {
    this.vue_instance = _vue_instance;
    this.vue_instance.$router.onReady( () => {
      this.onRouterReady();
    });
  }

  private pushToRouter(to: Navigatable): void {
    if(this.isLocation(to)) {
      this.vue_instance.$router.push(to);
    } else {
      this.vue_instance.$router.push(this.getPath(to));
    }
  }

  isCurrentlyRootPath(): boolean {
    return this.root_path == this.getPath(this.getCurrentNavigatable());
  }

  isCurrentlyAt(to: Navigatable): boolean {
    return this.navigatablesAreSame(to, this.getCurrentNavigatable());
  }

  getCurrentNavigatable(): Navigatable {
    return this.vue_instance.$router.currentRoute;
  }
  
  navigatablesAreSame(navigatable_1: Navigatable, navigatable_2: Navigatable): boolean {
    return this.getPath(navigatable_1) == this.getPath(navigatable_2);
  }

  /** 
   * @throws {ModuleNotFound}
   */
  canAccess(navigatable: Navigatable): boolean {
    if (this.isRoute(navigatable)) {
      const module: AppModule = this.module_provider.getModuleByRoute(navigatable);
      return this.module_provider.canAccessModule(module);
    } else if(this.isLocation(navigatable)) {
      const loc: Location = navigatable as Location;
      if(loc.name) {
        const module: AppModule = this.module_provider.getModuleByRouteName(loc.name);
        return this.module_provider.canAccessModule(module);
      } else if(loc.path) {
        const re = new RegExp('^/?(\\w*)', 'i');
        const matches: Array<string> | null = loc.path.match(re)
        if(matches && matches.length >= 1) {
          const module: AppModule = this.module_provider.getModuleByRouteName(matches[1]);
          return this.module_provider.canAccessModule(module);
        }
      }
    } else {
      return this.module_provider.canAccessModule(navigatable);
    }
    return false;
  }

  /** 
    * @throws {NoAccessibleModule}
    */
  navigateToIfPossible(to: Navigatable, alt?: Navigatable): Navigatable {
    if(this.canAccess(to)) {
      this.pushToRouter(to);
      return to;
    } else if(alt && this.canAccess(alt)) {
      this.pushToRouter(alt);
      return alt;
    } else {
      throw new NoAccessibleModule('No accessible route provided');
    }
  }

  getPath(navigatable: Navigatable): string {
    if(this.isAppModule(navigatable)) {
      return navigatable.route.path;
    } else if(this.isRoute(navigatable)) {
      return navigatable.path;
    } else if(this.isShengciModuleEnum(navigatable)) {
      const app_mod: AppModule | undefined = app_modules_imported.get((navigatable as SHENGCI_MODULES));
      if(app_mod) {
        return app_mod.route.path;
      }
    } else if(this.isLocation(navigatable)) {
      return navigatable.path ?? "";
    }
    return "";
    //return this.module_provider.getModule(navigatable).route.path;
  }

  isShengciModuleEnum(navigatable: Navigatable): navigatable is SHENGCI_MODULES {
    return app_modules_imported.get((navigatable as SHENGCI_MODULES)) !== undefined;
  }

  isRoute(navigatable: Navigatable): navigatable is Route {
    const route: Route = navigatable as Route; 
    return route.path !== undefined && route.hash !== undefined && route.query !== undefined;
  }

  isAppModule(navigatable: Navigatable): navigatable is AppModule {
    return (navigatable as AppModule).title !== undefined;
  }

  isLocation(navigatable: Navigatable): navigatable is Location {
    const loc: Location = navigatable as Location;
    return loc.name !== undefined || loc.path !== undefined;
  }
}

/*
export interface Location {
  name?: string
  path?: string
  hash?: string
  query?: Dictionary<string | (string | null)[] | null | undefined>
  params?: Dictionary<string>
  append?: boolean
  replace?: boolean
}

export interface Route {
  path: string
  name?: string | null
  hash: string
  query: Dictionary<string | (string | null)[]>
  params: Dictionary<string>
  fullPath: string
  matched: RouteRecord[]
  redirectedFrom?: string
  meta?: RouteMeta
}
*/