import { NotificationType, NotificationAction, NotificationCategory, Nullable, NotificationManager } from '@/types';
import { Observable, Subject, Subscription } from 'rxjs';
import Vue from 'vue';

/*
 * Notifications have two clearly defined actions: the 'action' and close
 * Toast notifications always close on route change
 * Rule 1: We define components will consume which notifications. We do not need a queue to keep track of notification consumption
*/
export class AppNotification
{
    public message: string;
    public type: NotificationType;
    public action: Nullable<NotificationAction> = null; //what this notification will do or trigger
    public closer: Nullable<Observable<boolean>> =  null; //listen to signal to close (the user may do another action that satisfies the notification)
    public is_open: boolean = false;
    public id: number;
    public is_unique: boolean = false;
    public notification_category: Nullable<NotificationCategory> = null; //if notification has a category, then it must be unique ie disallow successive notifications of this category
    public owner: Nullable<NotificationManager> = null; //the parent component who owns this notification
    
    constructor(message: string, type: NotificationType, action?: Nullable<NotificationAction>, closer?: Nullable<Observable<boolean>>, category?: Nullable<NotificationCategory>) {
        this.message = message;
        this.type = type;
        if(action) this.action = action;
        if(category) this.notification_category = category;
        this.id = Math.random() * 100 +  (new Date(Date.now())).getTime();
        if(closer) {
            this.closer = closer;
            const subscription: Subscription = this.closer.subscribe({
                next: () => {
                    subscription.unsubscribe();
                    this.closeNotification();
                }
            });
        }
        
    }

    public closeNotification(): void {
        if(this.owner) {
            this.owner.closeNotification(this);
        }
        console.log('closing notification');
    }

    public mustBeUnique(): boolean {
        return this.notification_category !== null;
    }
}