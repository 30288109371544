import {injectable} from 'inversify';
//import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import { Subject } from 'rxjs';
//import {from, Observable} from 'rxjs';
import { MessageDispatcher } from '@/services/MessageDispatcher/MessageDispatcher.inf';
import { AppNotification } from '@/classes/AppNotification';

@injectable()
export class MessageDispatcherImp implements MessageDispatcher
{
    readonly notifications$: Subject<AppNotification> = new Subject<AppNotification>();

    pushNotification(notification: AppNotification): void {
        this.notifications$.next(notification);
    }
}
