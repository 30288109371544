import { inject, injectable } from 'inversify';
import {TYPES} from '@/ioc/types';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import {from, Observable, throwError} from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share} from 'rxjs/operators';
import { LiebiaoManager } from '@/services/LiebiaoManager/LiebiaoManager.inf';
import { Hanasu, VocabularyService } from '@/services';
import { TagRequest, TagResult, Tag, SimpleValidationResult, LiebiaoRequest, LiebiaoResult, Liebiao, Vocabulary } from '@/types';
import { Sentence } from '@/types';
import Ajv, {JSONSchemaType} from "ajv";
import addFormats from "ajv-formats";
import {ValidationFailed} from '@/exceptions';

type SentenceData = {
    id: number;
    text: string;
    is_head: number;
}

type VocabularyData = {
    id: number;
    created_at: string;
    pinyin: string;
    no_quiz: boolean;
    sentences: Array<SentenceData>;
}

type LiebiaoData = {
    id: number;
    name: string;
    description: string;
    created_at: string;
    total: number;
    vocabularies: Array<VocabularyData>;
}

type LiebiaoResultsData = {
    request_id: number,
    liebiaos: Array<LiebiaoData>
    page: number;
    total: number;
}

export const liebiao_params_schema: JSONSchemaType<LiebiaoResultsData> = {
    $id: "shengci.com/schemas/tag_params.json",
    type: "object",
    properties: {
        request_id: {type: "integer", minimum: 1},
        page: {type: "integer", minimum: 1},
        total: {type: "integer", minimum: 0},
        liebiaos: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    id: {type: "integer", minimum: 1},
                    name: {type: "string"},
                    description: {type: "string"},
                    created_at: {type: "string", format: "date-time"},
                    total: {type: "integer"},
                    vocabularies : {
                        type: "array",
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                pinyin: {type: "string", nullable:true},
                                created_at: {type: "string", format: "date-time"},
                                no_quiz: {type: "boolean", nullable:false},
                                sentences: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                        properties: {
                                            id: {type: "integer", minimum: 1},
                                            text: {type: "string"},
                                            is_head: {type: "integer", minimum:0, maximum:1}
                                        },
                                        required: ["id", "text", "is_head"],
                                        additionalProperties: false
                                    }
                                }
                            },
                            required: ["id", "pinyin", "created_at", "sentences", "no_quiz"],
                            additionalProperties: false
                        }
                    }
                },
                required: ["id", "name", "created_at", "total", "vocabularies"],
                additionalProperties: false
            }
        }
    },
    required: ["request_id", "liebiaos"],
    additionalProperties: false
}

const ajv = new Ajv();
addFormats(ajv);

export const liebiao_params_validator = ajv.compile<LiebiaoResultsData>(liebiao_params_schema)

@injectable()
export class LiebiaoManagerImp implements LiebiaoManager
{
    private readonly hanasu: Hanasu;
    private readonly vocabulary_service: VocabularyService;
    private readonly lists_url: string = "api/lists/index";
    private readonly validate_liebiao_url: string = "api/lists/validate";
    
    public constructor(
        @inject(TYPES.HANASU_INSTANCE) _hanasu: Hanasu,
        @inject(TYPES.VOCABULARYSERVICE_INSTANCE) _vocabulary_service: VocabularyService,
    ) {
        this.hanasu = _hanasu;
        this.vocabulary_service = _vocabulary_service;
    }

    validateLiebiao(liebiao_request: LiebiaoRequest): Observable<SimpleValidationResult> {
        return this.hanasu.postBuffered<TagRequest, SimpleValidationResult>(this.validate_liebiao_url, liebiao_request).pipe(
            map( (response: AxiosResponse<SimpleValidationResult>) => {
                return response.data;
            }),
            catchError( (error) => {
                return throwError(error);
            })
        );
    }

    requestLiebiaos(liebiao_request: LiebiaoRequest): Observable<LiebiaoResult> {
        return this.hanasu.postBuffered<LiebiaoRequest, LiebiaoResultsData>(this.lists_url, liebiao_request).pipe(
            map( (response: AxiosResponse<LiebiaoResultsData>) => {
                if(process.env.VUE_APP_DEBUG) {
                    if(!liebiao_params_validator(response.data)) {
                        throw new ValidationFailed(liebiao_params_validator.errors);
                    }
                }
                const liebiaos: Array<Liebiao> = [];
                response.data.liebiaos.forEach((liebiao_data: LiebiaoData) => {
                    const vocabularies: Array<Vocabulary> = new Array<Vocabulary>();
                    liebiao_data.vocabularies.forEach( (vocabulary_data: VocabularyData) => {
                        vocabularies.push(this.makeVocabulary(vocabulary_data));
                    });
                    liebiaos.push({
                        id: liebiao_data.id,
                        name: liebiao_data.name,
                        description: liebiao_data.description,
                        created_at: new Date(liebiao_data.created_at),
                        total: liebiao_data.total,
                        vocabularies: vocabularies
                    });
                });
                return {
                    request_id: response.data.request_id,
                    liebiaos: liebiaos,
                    page: response.data.page,
                    total: response.data.total
                }
            })
        );
    }

    makeLiebiao(liebiao_data: LiebiaoData): Liebiao {
        const vocabularies: Array<Vocabulary> = new Array<Vocabulary>(); 
        return {
            id: liebiao_data.id,
            name: liebiao_data.name,
            description: liebiao_data.description,
            created_at: new Date(liebiao_data.created_at),
            total: liebiao_data.total,
            vocabularies: vocabularies
        }
    }

    makeVocabulary(vocabulary_data: VocabularyData): Vocabulary {
        let head: Sentence = {id:0, text:"", is_word:false};
        const other_sentences: Array<Sentence> = new Array<Sentence>();
        vocabulary_data.sentences?.forEach( (sentence_data: SentenceData) => {
            if(sentence_data.is_head) {
                head = {id:sentence_data.id, text:sentence_data.text};
            } else {
                other_sentences.push({id:sentence_data.id, text:sentence_data.text});
            }
        });
        const vocabulary: Vocabulary = {
            id: vocabulary_data.id,
            no_quiz: vocabulary_data.no_quiz,
            created_at: vocabulary_data.created_at,
            pinyin: vocabulary_data.pinyin,
            head: head,
            sentences: other_sentences
        };
        return vocabulary;
    }
}
