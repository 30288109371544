



















































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

@Component({
  components: {
    
  }
})
export default class AsyncButton extends Vue {
  @Prop({ default: 'XYZ' }) title!: string;
  @Prop({ default: '' }) icon!: string;
  @Prop({ default: 'info' }) color!: string;
  @Prop({ default: '' }) type!: string;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: false }) small!: boolean;

  @Emit()
  onclick(event: Event): Event {
    return event;
  }
}
