




































import { Component, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import {Authenticator, MessageDispatcher, NavRouter} from '@/services';
import {lazyInject} from '@/ioc/inversify.config';
import {TYPES} from '@/ioc/types';
import { NotificationType, Nullable, NotificationManager} from '@/types';
//import remove from 'lodash/remove';
import { Route } from 'vue-router';
import {SHENGCI_MODULES} from '@/enums';
import { AppNotification } from '@/classes/AppNotification';
import {from, of, Observable, concat, Subject, throwError, Subscription, asyncScheduler } from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share, takeUntil, retry, tap, mergeMap, concatMap, debounceTime, throttleTime, mapTo, filter} from 'rxjs/operators';

@Component({
  components: {
    AsyncButton,
  }
})
export default class StickyNotificationsComponent extends Vue implements NotificationManager
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MESSAGEDISPATCHER_INSTANCE)
  private message_dispatcher!: MessageDispatcher;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  readonly max_notifications: number = 3;

  public notifications: Array<AppNotification> = new Array<AppNotification>();
  public notification_queue: Array<AppNotification> = new Array<AppNotification>();

  closeNotification(notification: AppNotification): void {
    notification.is_open = false;
    const next_notification: Nullable<AppNotification> = this.notification_queue.shift() ?? null;
    if(next_notification) {
       this.notifications.push(next_notification);
    } else {
      setTimeout(() => this.tryEmptyNotifications(), 1000);
    }
  }

  tryEmptyNotifications(): void {
    let still_open: boolean = false;
    if(this.notifications.length > 0) {
      for(let i = 0; i < this.notifications.length; i++) {
        if(this.notifications[i].is_open) {
          still_open = true;
          break;
        }
      }
      if(!still_open) {
        this.notifications.splice(0, this.notifications.length);
      }
    }
  }

  /*
  this didnt work because for some reason vuetify will trigger transition animations on the alert after the array is modified
  dissmisNotification(notification_id: number): void {
    const index: number = this.notifications.findIndex((notification: Notification) => {
      return notification.id == notification_id;
    })
    this.notifications.splice(index, 1);
  }
  */

  created(): void {
    this.message_dispatcher.notifications$.pipe(
      filter( (notification: AppNotification) => notification.type == "sticky")
      ).subscribe({
      next: (notification: AppNotification) => {
        if(notification.mustBeUnique() && this.notificationAlreadyExists(notification)) {
          return;
        }
        notification.owner = this;
        if(this.notifications.length > this.max_notifications) {
          this.notification_queue.push(notification);
        } else {
          this.notifications.push(notification);
          setTimeout( () => {
            notification.is_open = true;
          }, 100);
        }
      },
    });
  }

  notificationAlreadyExists(in_notification: AppNotification): boolean {
    let all_notifications = this.notifications.concat(this.notification_queue);
    if(all_notifications.length <= 0) return false;
    for(let i=0; i<=all_notifications.length; i++) {
      if(in_notification.notification_category && in_notification.notification_category == all_notifications[i].notification_category) {
        return true;
      }
    }
    return false;
  }
}
