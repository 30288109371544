import { VocabularyStatus } from '@/types';

export const getStatusColor : (status: VocabularyStatus) => string = (status: VocabularyStatus) => {
    switch(status) {
      case "learning":
        return "yellow";
      case "reviewing":
        return "orange";
      case "maintaining":
        return "green";
      case "mastered":
        return "blue";
      default:
      case "unfamiliar":
        return "gray";
    }
}

export const getStatusString : (status: string) => VocabularyStatus = (status: string) => {
    if(status == "unfamiliar") return "unfamiliar";
    if(status == "learning") return "learning";
    if(status == "reviewing") return "reviewing";
    if(status == "maintaining") return "maintaining";
    if(status == "mastered") return "mastered";
    return "unfamiliar";
}

export const getStatusTitle : (status: VocabularyStatus) => string = (status: VocabularyStatus) => {
    if(status == "unfamiliar") return "unfamiliar";
    if(status == "learning") return "learning";
    if(status == "reviewing") return "reviewing";
    if(status == "maintaining") return "maintaining";
    if(status == "mastered") return "mastered";
    return "unfamiliar";
}