import { inject, injectable } from 'inversify';
import {TYPES} from '@/ioc/types';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import {from, Observable, concat, Subject, throwError } from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share} from 'rxjs/operators';
import { VocabularyService } from '@/services/VocabularyService/VocabularyService.inf';
/* import { Vocabulary, VocabularyParams } from '@/classes/Vocabulary'; */
import { Hanasu, MessageDispatcher } from '@/services';
import { VocabularyPageResult, NewVocabularyData, VocabularyPageRequest, VocabularyEvent, VocabularyState, VocabularyManager, Sentence, Liebiao, VocabulayStateStoreRequest, PaginateParams, SortParams, SearchParams } from '@/types';
import { VocabularySelectionState, Tag, Vocabulary, VocabularyStatus } from '@/types';
import { WAIT_TIMES } from '@/enums';
import Ajv, { JSONSchemaType } from "ajv";
import addFormats from "ajv-formats";
import { ValidationFailed, InvalidPageSize, ChunkingError } from '@/exceptions';
import { AppURL } from '@/classes/AppURL';
import { VocabularyQuery } from '@/classes/VocabularyQuery';
import difference from 'lodash/difference';
import remove from 'lodash/remove';
import { VocabularyQueryParams } from '@/classes/VocabularyQueryParams';
import { log } from '@/helpers/logger';

type SentenceData = {
    id: number;
    text: string;
    is_word: number;
    is_head: number
}

type VocabularyData = {
    id: number;
    //head: {id:number, text:string, is_word:boolean};
    created_at: string;
    selected: boolean;
    status?: string;
    last_quiz?: string;
    promoted?: string;
    demote?: string;
    pinyin?: string;
    audio_file?: {full_url: string, file_name: string};
    sentences?: Array<SentenceData>;
    tags?: Array<{id:number, name:string, description: string, created_at: string}>;
    note?: {id:number, content:string};
    liebiaos?: Array<{id:number, name:string, description: string, created_at: string}>;
    no_quiz: boolean;
}

type VocabularyQueryResultsData = {
    total: number;
    page: number;
    taken: number;
    query_id: string;
    vocabulary_data: Array<VocabularyData>
}

export type SearchParamsData = {
    mode: string;
    text?: string;
    search_column?: number;
    regexp?: boolean;
    date_to?: string;
    date_from?: string;
    tags_include?: Array<number>;
    tags_exclude?: Array<number>;
    liebiaos?: Array<number>;
    statuses?: Array<string>;
}

type VocabularyPageRequestData = {
    query_id: string;
    pagination_params: PaginateParams;
    sort_params: SortParams;
    state_store_request?: {
        vocabulary_states?: Array<VocabularySelectionState>;
        all?: boolean;
        none?: boolean;
    };
    vocabulary_data?: NewVocabularyData;
    delete_request?: {
        vocabulary_ids?: Array<number>;
        selected?: boolean;
    };
    search_params?: SearchParamsData;
}

type VocabulayStateStoreRequestData = {
    query_id: string;
    state_store_request?: {
        vocabulary_states?: Array<VocabularySelectionState>;
        all?: boolean;
        none?: boolean;
    };
}

type FlushQueryData = {
    query_id: string;
}

const vocabulary_query_results_data_schema: JSONSchemaType<VocabularyQueryResultsData> = {
    type: "object",
    properties: {
        total: {type: "integer", minimum: 0},
        page: {type: "integer", minimum: 0},
        taken: {type: "integer", minimum: 0},
        query_id:  {type: "string"},
        vocabulary_data: {
            type: "array",
            items: {
                type: "object",
                properties: {
                    id: {type: "integer", minimum: 1},
                    selected: {type: "boolean", nullable: true},
                    /* created_at: {type: "string", format: "date-time", nullable: true}, */
                    created_at: {type: "string", nullable: false},
                    last_quiz: {type: "string", nullable: true},
                    promoted: {type: "string", nullable: true},
                    demote: {type: "string", nullable: true},
                    status: {type: "string", nullable: true},
                    pinyin: {type: "string", nullable: true},
                    no_quiz: {type: "boolean", nullable: false},
                    note: {
                        type: "object", nullable: true,
                        properties: {
                            id: {type: "integer", minimum: 1},
                            content: {type: "string"},
                        },
                        required: ["id", "content"],
                        additionalProperties: false
                    },
                    audio_file: {
                        type: "object", nullable: true,
                        properties: {
                            full_url: {type: "string"},
                            file_name: {type: "string"},
                        },
                        required: ["full_url", "file_name"],
                        additionalProperties: false
                    },
                    sentences: {
                        type: "array", nullable: true, minItems: 1,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                text: {type: "string"},
                                is_word: {type: "integer", minimum: 0, maximum: 1},
                                is_head: {type: "integer", minimum: 0, maximum: 1},
                            },
                            required: ["id", "text", "is_word", "is_head"],
                            additionalProperties: false
                        },
                    },
                    tags: {
                        type: "array", nullable: true,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                name: {type: "string"},
                                description: {type: "string"},
                                created_at: {type: "string", format: "date-time"},
                            },
                            required: ["id", "name", "description", "created_at"],
                            additionalProperties: false
                        }
                    },
                    liebiaos: {
                        type: "array", nullable: true,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                name: {type: "string"},
                                description: {type: "string"},
                                created_at: {type: "string", format: "date-time"}
                            },
                            required: ["id", "name", "description", "created_at"],
                            additionalProperties: false
                        }
                    }
                },
                required: ["id", "created_at"],
                additionalProperties: false
            }
        }
    },
    required: ["total", "taken", "page", "query_id", "vocabulary_data"],
}


const ajv = new Ajv();
addFormats(ajv);

//process.env.VUE_APP_DEBUG || "production"

const vocabulary_query_results_data_validator = ajv.compile<VocabularyQueryResultsData>(vocabulary_query_results_data_schema);

@injectable()
export class VocabularyServiceImp implements VocabularyService
{
    private readonly hanasu: Hanasu;
    private readonly message_dispatcher: MessageDispatcher;
    private readonly vocabulary_store_url: string = "api/vocabulary/store";
    private readonly vocabulary_storestate_url: string = "api/vocabulary/storestate";
    private readonly vocabulary_index_url: string = "api/vocabulary/index";
    private readonly vocabulary_flushquery_url: string = "api/vocabulary/flushquery";
    public vocabulary_events$: Subject<VocabularyEvent> = new Subject<VocabularyEvent>();
    
    public constructor(
        @inject(TYPES.HANASU_INSTANCE) _hanasu: Hanasu,
        @inject(TYPES.MESSAGEDISPATCHER_INSTANCE) _message_dispatcher: MessageDispatcher,
    ) {
        this.hanasu = _hanasu;
        this.message_dispatcher = _message_dispatcher;
    }

    public requestPage(page_request: VocabularyPageRequest): Observable<VocabularyPageResult> {
        const vocab_form_data = new FormData();
        const page_request_data: VocabularyPageRequestData = {
            query_id: page_request.query_id,
            pagination_params: {
                page: page_request.query_params.paginate_params.page,
                page_size: page_request.query_params.paginate_params.page_size,
            },
            sort_params: {
                order: page_request.query_params.sort_params.order,
                column: page_request.query_params.sort_params.column,
            }
        };
       if(page_request.query_params.search_params) {
            if(page_request.query_params.search_params.mode == "simple") {
                page_request_data.search_params = {
                    mode: "simple",
                };
                if(page_request.query_params.search_params.text.length > 0) {
                    page_request_data.search_params.text = page_request.query_params.search_params.text;
                }
            } else if(page_request.query_params.search_params.mode == "advanced") {
                page_request_data.search_params = {
                    mode: "advanced",
                };
                if(page_request.query_params.search_params.text.length > 0) {
                    page_request_data.search_params.text = page_request.query_params.search_params.text;
                    page_request_data.search_params.regexp = page_request.query_params.search_params.regexp;
                }
                if(page_request_data.search_params.regexp) {
                    page_request_data.search_params.regexp = page_request.query_params.search_params.regexp;
                }
                if(page_request.query_params.search_params.search_column) {
                    page_request_data.search_params.search_column = page_request.query_params.search_params.search_column;
                }
                if(page_request.query_params.search_params.date_to) {
                    page_request_data.search_params.date_to = page_request.query_params.search_params.date_to.toISOString(); 
                }
                if(page_request.query_params.search_params.date_from) {
                    page_request_data.search_params.date_from = page_request.query_params.search_params.date_from.toISOString(); 
                }
                if(page_request.query_params.search_params.tags_include.length > 0) {
                    page_request_data.search_params.tags_include = new Array<number>();
                    page_request.query_params.search_params.tags_include.forEach((tag: Tag) => {
                        page_request_data.search_params?.tags_include?.push(tag.id);
                    });
                }
                if(page_request.query_params.search_params.tags_exclude.length > 0) {
                    page_request_data.search_params.tags_exclude = new Array<number>();
                    page_request.query_params.search_params.tags_exclude.forEach((tag: Tag) => {
                        page_request_data.search_params?.tags_exclude?.push(tag.id);
                    });
                }
                
            }
            if(page_request.query_params.search_params.liebiaos.length > 0 && page_request_data.search_params) {
                page_request_data.search_params.liebiaos = new Array<number>();
                page_request.query_params.search_params.liebiaos.forEach((liebiao: Liebiao) => {
                    page_request_data.search_params?.liebiaos?.push(liebiao.id);
                });
            }
            if(page_request.query_params.search_params.statuses.length > 0 && page_request_data.search_params) {
                page_request_data.search_params.statuses = new Array<VocabularyStatus>();
                page_request.query_params.search_params.statuses.forEach((status: VocabularyStatus) => {
                    page_request_data.search_params?.statuses?.push(status);
                });
            }
        }

        if(page_request.query_params.state_store_request) {
            page_request_data.state_store_request = {};
            if(page_request.query_params.state_store_request.vocabulary_states) {
                page_request_data.state_store_request.vocabulary_states = page_request.query_params.state_store_request.vocabulary_states;
            } else if(page_request.query_params.state_store_request.all) {
                page_request_data.state_store_request.all = page_request.query_params.state_store_request.all;
            } else if(page_request.query_params.state_store_request.none) {
                page_request_data.state_store_request.none = page_request.query_params.state_store_request.none;
            }
        }

        if(page_request.query_params.delete_request) {
            page_request_data.delete_request = {};
            if(page_request.query_params.delete_request.vocabulary_ids) page_request_data.delete_request.vocabulary_ids = page_request.query_params.delete_request.vocabulary_ids;
            if(page_request.query_params.delete_request.selected) page_request_data.delete_request.selected = page_request.query_params.delete_request.selected;
        }

        if(page_request.query_params.store_request) {
            page_request_data.vocabulary_data = {
                head: page_request.query_params.store_request.head,
                no_quiz: page_request.query_params.store_request.no_quiz
            };
            if(page_request.query_params.store_request.id) {
                page_request_data.vocabulary_data.id = page_request.query_params.store_request.id;
            }
            if(page_request.query_params.store_request.pinyin) {
                page_request_data.vocabulary_data.pinyin = page_request.query_params.store_request.pinyin;
            }
            if(page_request.query_params.store_request.note) {
                page_request_data.vocabulary_data.note = page_request.query_params.store_request.note;
            }
            if(page_request.query_params.store_request.translations) {
                page_request_data.vocabulary_data.translations = page_request.query_params.store_request.translations;
            }
            if(page_request.query_params.store_request.tags) {
                page_request_data.vocabulary_data.tags = page_request.query_params.store_request.tags;
            }
            if(page_request.query_params.store_request.liebiaos) {
                page_request_data.vocabulary_data.liebiaos = page_request.query_params.store_request.liebiaos;
            }
            if(page_request.query_params.store_request.audio_file) {
                vocab_form_data.set('audio_file', page_request.query_params.store_request.audio_file);
            }
            if(page_request.query_params.store_request.status) {
                page_request_data.vocabulary_data.status = page_request.query_params.store_request.status;
            }
        }

        //log(page_request_data);

        vocab_form_data.set('page_request_data', JSON.stringify(page_request_data));

        //return this.hanasu.postBuffered<VocabularyPageRequestData, VocabularyQueryResultsData>(this.vocabulary_index_url, page_request_data).pipe(
        return this.hanasu.postBuffered<FormData, VocabularyQueryResultsData>(this.vocabulary_index_url, vocab_form_data).pipe(
            map((response: AxiosResponse<VocabularyQueryResultsData>) => {
                if(process.env.VUE_APP_DEBUG) {
                    if(!vocabulary_query_results_data_validator(response.data)) {
                        throw new ValidationFailed(vocabulary_query_results_data_validator.errors);
                    }
                }
                const vocabulary_states: Array<VocabularyState> = new Array<VocabularyState>();
                response.data.vocabulary_data.forEach( (vocabulary_data: VocabularyData) => {
                    vocabulary_states.push(this.makeVocabularyState(vocabulary_data));
                });
                return {
                    query_id: response.data.query_id,
                    total: response.data.total,
                    page: response.data.page,
                    taken: response.data.taken,
                    vocabulary_data: vocabulary_states
                }; 
                
            })
        );
    }

    public flushQuery(vocabulary_query: VocabularyQuery): Promise<boolean> {
        return new Promise( (resolve,reject) => {
            if(!vocabulary_query.query_id) {
                reject(true);
                return;
            }
            const query_flush_data: FlushQueryData = {
                query_id: vocabulary_query.query_id
            };
            this.hanasu.postBuffered<FlushQueryData,any>(this.vocabulary_flushquery_url, query_flush_data, this.hanasu.default_excluded_status_codes, WAIT_TIMES.SLOWER).subscribe({
                next: () => {
                    resolve(true);
                }
            });
        }); 
    }

    
    public storeVocabState(vocabulary_states: VocabulayStateStoreRequest): Promise<boolean> {
        const vocabulary_state_store_request: VocabulayStateStoreRequestData = {
            query_id: vocabulary_states.query_id,
        };
        if(vocabulary_states.vocabulary_states) {
            vocabulary_state_store_request.state_store_request = {
                vocabulary_states: vocabulary_states.vocabulary_states
            }; 
        } else if(vocabulary_states.all) {
            vocabulary_state_store_request.state_store_request = {
                all: true
            }; 
        } else if(vocabulary_states.none) {
            vocabulary_state_store_request.state_store_request = {
                none: true
            }; 
        }
        return new Promise<boolean>( (resolve,reject) => {
            this.hanasu.postBuffered<VocabulayStateStoreRequestData,any>(this.vocabulary_storestate_url, vocabulary_state_store_request, this.hanasu.default_excluded_status_codes, WAIT_TIMES.SLOWER).subscribe({
                next: () => {
                    resolve(true);
                }
            });
        }); 
    }

    public broadcastVocabularyEvent(vocabulary_event: VocabularyEvent): void {
        //console.log(`broadcastVocabularyEvent: ${vocabulary_event.type}`);
        this.vocabulary_events$.next(vocabulary_event);
    }

    public makeVocabularyState(vocabulary_data: VocabularyData): VocabularyState {
        const vocabulary: Vocabulary = this.makeVocabulary(vocabulary_data);
        return {
            selected: Boolean(vocabulary_data.selected),
            vocabulary: vocabulary
        };
    }

    public getStatusString(status: string): VocabularyStatus {
        if(status == "unfamiliar") return "unfamiliar";
        if(status == "learning") return "learning";
        if(status == "reviewing") return "reviewing";
        if(status == "maintaining") return "maintaining";
        if(status == "mastered") return "mastered";
        return "unfamiliar";
    }

    public makeVocabulary(vocabulary_data: VocabularyData): Vocabulary {
        const vocabulary_tags: Array<Tag> = new Array<Tag>();
        vocabulary_data.tags?.forEach((tag_data: {id:number, name:string, description: string, created_at: string}) => {
            vocabulary_tags.push({
                id: tag_data.id,
                name: tag_data.name,
                description: tag_data.description,
                created_at: new Date(tag_data.created_at)
            });
        });
        let head: Sentence = {id:0, text:"", is_word:false};
        const other_sentences: Array<Sentence> = new Array<Sentence>();
        vocabulary_data.sentences?.forEach( (sentence_data: SentenceData) => {
            if(sentence_data.is_head) {
                head = {id:sentence_data.id, text:sentence_data.text, is_word:Boolean(sentence_data.is_word)};
            } else {
                other_sentences.push({id:sentence_data.id, text:sentence_data.text, is_word:Boolean(sentence_data.is_word)});
            }
        });
        const vocabulary: Vocabulary = {
            id: vocabulary_data.id,
            head: head,
            created_at: vocabulary_data.created_at,
            pinyin: vocabulary_data.pinyin ?? "",
            audio_file: new AppURL(vocabulary_data.audio_file?.full_url ?? "", false, vocabulary_data.audio_file?.file_name ?? ""),
            sentences: other_sentences,
            tags: vocabulary_tags,
            note: vocabulary_data.note ?? {id:0, content:""},
            no_quiz: vocabulary_data.no_quiz
            //liebiaos: vocabulary_data.liebiaos ?? new Array<Liebiao>(),
        }
        if(vocabulary_data.status) {
            vocabulary.status = this.getStatusString(vocabulary_data.status);
        }
        if(vocabulary_data.last_quiz) {
            vocabulary.last_quiz = vocabulary_data.last_quiz;
        }
        const vocabulary_liebiao: Array<Liebiao> = new Array<Liebiao>();
        if(vocabulary_data.liebiaos) {
            vocabulary_data.liebiaos.forEach( (liebiao: {id:number, name:string, description: string, created_at: string}) => {
                vocabulary_liebiao.push({
                    id: liebiao.id,
                    name: liebiao.name,
                    description: liebiao.description,
                    created_at: new Date(liebiao.created_at)
                });
            });
            vocabulary.liebiaos = vocabulary_liebiao;
        }
        return vocabulary;
    }

    public getVocabularyQuery(owner: VocabularyManager): VocabularyQuery {
        return  new VocabularyQuery(this, this.message_dispatcher, owner);
    }

    /*
    public storeVocabulary(vocab: StoreVocabularyData): Promise<boolean> {
        const vocab_form_data = new FormData();
        if(vocab.id) {
            vocab_form_data.set('id', vocab.id.toString());
        }
        vocab_form_data.set('head', vocab.head);
        if(vocab.pinyin) {
            vocab_form_data.set('pinyin', vocab.pinyin);
        }
        if(vocab.audio_file) {
            vocab_form_data.set('audio_file', vocab.audio_file);
        }
        if(vocab.tranlsations) {
            vocab.tranlsations.forEach((translation: string) => {
                if(translation.length>0) vocab_form_data.append('translations[]', translation)
            });
        }
        if(vocab.tags) {
            vocab.tags.forEach((tag: string)=>vocab_form_data.append('tags[]', tag));
        }
        //console.log(vocab_form_data);
        //TODO we generally havent been catching errors
        return new Promise( (resolve,reject) => {
            this.hanasu.postBuffered<FormData,any>(this.vocabulary_store_url, vocab_form_data, this.hanasu.default_excluded_status_codes, WAIT_TIMES.SLOWER).subscribe({
                next: () => {
                    resolve(true);
                }
            });
        }); 
    }
    */
    
}
