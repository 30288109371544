import { inject, injectable } from 'inversify';
import {TYPES} from '@/ioc/types';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import {from, Observable, throwError, of, Subscription} from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share} from 'rxjs/operators';
import { QuizService } from '@/services/QuizService/QuizService.inf';
import { Hanasu } from '@/services';
import { TagRequest, TagResult, Tag, SimpleValidationResult, Vocabulary, Sentence, Liebiao, VocabularyStatus, QuizEntry } from '@/types';
import Ajv, {JSONSchemaType} from "ajv";
import addFormats from "ajv-formats";
import {ValidationFailed, QuizResumeResultError} from '@/exceptions';
import { NewQuizData, NewQuizResult, QuizEntryResult, QuizEntryRequest, QuizSummaryResult, QuizSummaryEntryVerdict, QuizSummaryRequest, QuizResumeResult } from '@/types';
import { QuizSettings } from '@/types';
import { WAIT_TIMES } from '@/enums';
import { AppURL } from '@/classes/AppURL';
import { getStatusString } from '@/helpers/StatusHelpers';

type SentenceData = {
    id: number;
    text: string;
    is_word: number;
    is_head: number
}

type VocabularyData = {
    id: number;
    created_at: string;
    status?: string;
    last_quiz?: string;
    pinyin?: string;
    audio_file?: {full_url: string, file_name: string};
    sentences?: Array<SentenceData>;
    tags?: Array<{id:number, name:string, description: string, created_at: string}>;
    note?: {id:number, content:string};
    liebiaos?: Array<{id:number, name:string, description: string, created_at: string}>;
    no_quiz: boolean;
}

type VocabularyDataVerdict = VocabularyData & {
    verdict: boolean;
    new_status?: string;
}

type NewQuizResultData = {
    quiz_id: number;
    total: number;
    first: VocabularyData;
}

type QuizEntryResultData = {
    quiz_id: number;
    total: number;
    entry?: VocabularyData;
    quiz_index?: number;
    //quiz_summary?: QuizSummaryResultData;
    //quiz_settings: QuizSettings;
}

type QuizSummaryResultData = {
    total: number;
    right: number;
    wrong: number;
    started_at: string;
    finished_at: string;
    duration: string;
    verdicts: Array<VocabularyDataVerdict>;
}

type QuizResumeResultData = {
    quiz_id: number;
    quiz_total: number;
    quiz_settings: QuizSettings;
    quiz_index?: number;
    quiz_entry?: VocabularyData;
    quiz_summary?: QuizSummaryResultData;
}

type RecentlyQuizzedData = {
    verdicts: Array<VocabularyDataVerdict>;
}

const new_quiz_data_schema: JSONSchemaType<NewQuizResultData> = {
    $id: "shengci.com/schemas/new_quiz_result_params.json",
    type: "object",
    properties: {
        quiz_id: {type: "integer", minimum: 1},
        total: {type: "integer", minimum: 0},
        first: {type: "object", nullable:true,
        properties: {
            id: {type: "integer", minimum: 1},
            created_at: {type: "string", nullable: false},
            last_quiz: {type: "string", nullable: true},
            status: {type: "string", nullable: true},
            pinyin: {type: "string", nullable: true},
            no_quiz: {type: "boolean", nullable: false},
            note: {
                type: "object", nullable: true,
                properties: {
                    id: {type: "integer", minimum: 1},
                    content: {type: "string"},
                },
                required: ["id", "content"],
                additionalProperties: false
            },
            audio_file: {
                type: "object", nullable: true,
                properties: {
                    full_url: {type: "string"},
                    file_name: {type: "string"},
                },
                required: ["full_url", "file_name"],
                additionalProperties: false
            },
            sentences: {
                type: "array", nullable: true, minItems: 1,
                items: {
                    type: "object",
                    properties: {
                        id: {type: "integer", minimum: 1},
                        text: {type: "string"},
                        is_word: {type: "integer", minimum: 0, maximum: 1},
                        is_head: {type: "integer", minimum: 0, maximum: 1},
                    },
                    required: ["id", "text", "is_word", "is_head"],
                    additionalProperties: false
                },
            },
            tags: {
                type: "array", nullable: true,
                items: {
                    type: "object",
                    properties: {
                        id: {type: "integer", minimum: 1},
                        name: {type: "string"},
                        description: {type: "string"},
                        created_at: {type: "string", format: "date-time"},
                    },
                    required: ["id", "name", "description", "created_at"],
                    additionalProperties: false
                }
            },
            liebiaos: {
                type: "array", nullable: true,
                items: {
                    type: "object",
                    properties: {
                        id: {type: "integer", minimum: 1},
                        name: {type: "string"},
                        description: {type: "string"},
                        created_at: {type: "string", format: "date-time"}
                    },
                    required: ["id", "name", "description", "created_at"],
                    additionalProperties: false
                }
            }
        },
        required: ["id", "created_at", "no_quiz"],
        }
    },
    required: ["quiz_id", "total", "first"],
    additionalProperties: true
}

const quiz_entry_result_data_schema: JSONSchemaType<QuizEntryResultData> = {
    $id: "shengci.com/schemas/quiz_entry_result_params.json",
    type: "object",
    properties: {
        quiz_id: {type: "integer", nullable: false},
        quiz_index: {type: "integer", nullable: true},
        total: {type: "integer", nullable: false},
        entry: {type: "object", nullable:true,
            properties: {
                id: {type: "integer", minimum: 1},
                created_at: {type: "string", nullable: false},
                last_quiz: {type: "string", nullable: true},
                status: {type: "string", nullable: true},
                pinyin: {type: "string", nullable: true},
                no_quiz: {type: "boolean", nullable: false},
                note: {
                    type: "object", nullable: true,
                    properties: {
                        id: {type: "integer", minimum: 1},
                        content: {type: "string"},
                    },
                    required: ["id", "content"],
                    additionalProperties: false
                },
                audio_file: {
                    type: "object", nullable: true,
                    properties: {
                        full_url: {type: "string"},
                        file_name: {type: "string"},
                    },
                    required: ["full_url", "file_name"],
                    additionalProperties: false
                },
                sentences: {
                    type: "array", nullable: true, minItems: 1,
                    items: {
                        type: "object",
                        properties: {
                            id: {type: "integer", minimum: 1},
                            text: {type: "string"},
                            is_word: {type: "integer", minimum: 0, maximum: 1},
                            is_head: {type: "integer", minimum: 0, maximum: 1},
                        },
                        required: ["id", "text", "is_word", "is_head"],
                        additionalProperties: false
                    },
                },
                tags: {
                    type: "array", nullable: true,
                    items: {
                        type: "object",
                        properties: {
                            id: {type: "integer", minimum: 1},
                            name: {type: "string"},
                            description: {type: "string"},
                            created_at: {type: "string", format: "date-time"},
                        },
                        required: ["id", "name", "description", "created_at"],
                        additionalProperties: false
                    }
                },
                liebiaos: {
                    type: "array", nullable: true,
                    items: {
                        type: "object",
                        properties: {
                            id: {type: "integer", minimum: 1},
                            name: {type: "string"},
                            description: {type: "string"},
                            created_at: {type: "string", format: "date-time"}
                        },
                        required: ["id", "name", "description", "created_at"],
                        additionalProperties: false
                    }
                }
            },
            required: ["id", "created_at", "no_quiz"],
        }
    },
    required: ["quiz_id", "total",],
    additionalProperties: false
}

const quiz_resume_result_data_schema: JSONSchemaType<QuizResumeResultData> = {
    $id: "shengci.com/schemas/quiz_resume_result_params.json",
    type: "object",
    properties: {
        quiz_id: {type: "integer", nullable: false},
        quiz_total: {type: "integer", nullable: false},
        quiz_settings: {type: "object", nullable: false,
            properties: {
                reverse_mode: {type: "boolean", nullable: false},
                audio_mode: {type: "boolean", nullable: false},
            },
            required: ["reverse_mode", "audio_mode"]
        },
        quiz_index: {type: "integer", nullable: true},
        quiz_summary: {type: "object", nullable:true, 
            properties: {
                total: {type: "integer", nullable: false},
                right: {type: "integer", nullable: false},
                wrong: {type: "integer", nullable: false},
                started_at: {type: "string", nullable: false},
                finished_at: {type: "string", nullable: false},
                duration: {type: "string", nullable: false},
                verdicts: {type: "array", nullable: false,
                    items: {
                        type: "object",
                        properties: {
                            id: {type: "integer", minimum: 1},
                            created_at: {type: "string", nullable: false},
                            last_quiz: {type: "string", nullable: true},
                            status: {type: "string", nullable: true},
                            new_status: {type: "string", nullable: true},
                            verdict: {type: "boolean", nullable: true},
                            pinyin: {type: "string", nullable: true},
                            no_quiz: {type: "boolean", nullable: false},
                            note: {
                                type: "object", nullable: true,
                                properties: {
                                    id: {type: "integer", minimum: 1},
                                    content: {type: "string"},
                                },
                                required: ["id", "content"],
                                additionalProperties: false
                            },
                            audio_file: {
                                type: "object", nullable: true,
                                properties: {
                                    full_url: {type: "string"},
                                    file_name: {type: "string"},
                                },
                                required: ["full_url", "file_name"],
                                additionalProperties: false
                            },
                            sentences: {
                                type: "array", nullable: true, minItems: 1,
                                items: {
                                    type: "object",
                                    properties: {
                                        id: {type: "integer", minimum: 1},
                                        text: {type: "string"},
                                        is_word: {type: "integer", minimum: 0, maximum: 1},
                                        is_head: {type: "integer", minimum: 0, maximum: 1},
                                    },
                                    required: ["id", "text", "is_word", "is_head"],
                                    additionalProperties: false
                                },
                            },
                            tags: {
                                type: "array", nullable: true,
                                items: {
                                    type: "object",
                                    properties: {
                                        id: {type: "integer", minimum: 1},
                                        name: {type: "string"},
                                        description: {type: "string"},
                                        created_at: {type: "string", format: "date-time"},
                                    },
                                    required: ["id", "name", "description", "created_at"],
                                    additionalProperties: false
                                }
                            },
                            liebiaos: {
                                type: "array", nullable: true,
                                items: {
                                    type: "object",
                                    properties: {
                                        id: {type: "integer", minimum: 1},
                                        name: {type: "string"},
                                        description: {type: "string"},
                                        created_at: {type: "string", format: "date-time"}
                                    },
                                    required: ["id", "name", "description", "created_at"],
                                    additionalProperties: false
                                }
                            }
                        },
                        required: ["id", "created_at", "verdict", "no_quiz"]
                    }
                }
            },
            required: ["total", "right", "wrong", "started_at", "finished_at", "verdicts"]
        },
        quiz_entry: {type: "object", nullable:true,
            properties: {
                id: {type: "integer", minimum: 1},
                created_at: {type: "string", nullable: false},
                last_quiz: {type: "string", nullable: true},
                status: {type: "string", nullable: true},
                pinyin: {type: "string", nullable: true},
                no_quiz: {type: "boolean", nullable: false},
                note: {
                    type: "object", nullable: true,
                    properties: {
                        id: {type: "integer", minimum: 1},
                        content: {type: "string"},
                    },
                    required: ["id", "content"],
                    additionalProperties: false
                },
                audio_file: {
                    type: "object", nullable: true,
                    properties: {
                        full_url: {type: "string"},
                        file_name: {type: "string"},
                    },
                    required: ["full_url", "file_name"],
                    additionalProperties: false
                },
                sentences: {
                    type: "array", nullable: true, minItems: 1,
                    items: {
                        type: "object",
                        properties: {
                            id: {type: "integer", minimum: 1},
                            text: {type: "string"},
                            is_word: {type: "integer", minimum: 0, maximum: 1},
                            is_head: {type: "integer", minimum: 0, maximum: 1},
                        },
                        required: ["id", "text", "is_word", "is_head"],
                        additionalProperties: false
                    },
                },
                tags: {
                    type: "array", nullable: true,
                    items: {
                        type: "object",
                        properties: {
                            id: {type: "integer", minimum: 1},
                            name: {type: "string"},
                            description: {type: "string"},
                            created_at: {type: "string", format: "date-time"},
                        },
                        required: ["id", "name", "description", "created_at"],
                        additionalProperties: false
                    }
                },
                liebiaos: {
                    type: "array", nullable: true,
                    items: {
                        type: "object",
                        properties: {
                            id: {type: "integer", minimum: 1},
                            name: {type: "string"},
                            description: {type: "string"},
                            created_at: {type: "string", format: "date-time"}
                        },
                        required: ["id", "name", "description", "created_at"],
                        additionalProperties: false
                    }
                }
            },
            required: ["id", "created_at", "no_quiz"],
        }
    },
    required: ["quiz_id", "quiz_total", "quiz_settings"]
};

const quiz_summary_result_data_schema: JSONSchemaType<QuizSummaryResultData & {quiz_id: number}> = {
    $id: "shengci.com/schemas/quiz_summary_result_params.json",
    type: "object",
    properties: {
        quiz_id: {type: "integer", nullable: false},
        total: {type: "integer", nullable: false},
        right: {type: "integer", nullable: false},
        wrong: {type: "integer", nullable: false},
        started_at: {type: "string", nullable: false},
        finished_at: {type: "string", nullable: false},
        duration: {type: "string", nullable: false},
        verdicts: {type: "array", nullable: false,
            items: {
                type: "object",
                properties: {
                    id: {type: "integer", minimum: 1},
                    created_at: {type: "string", nullable: false},
                    last_quiz: {type: "string", nullable: true},
                    status: {type: "string", nullable: true},
                    new_status: {type: "string", nullable: true},
                    verdict: {type: "boolean", nullable: true},
                    pinyin: {type: "string", nullable: true},
                    no_quiz: {type: "boolean", nullable: false},
                    note: {
                        type: "object", nullable: true,
                        properties: {
                            id: {type: "integer", minimum: 1},
                            content: {type: "string"},
                        },
                        required: ["id", "content"],
                        additionalProperties: false
                    },
                    audio_file: {
                        type: "object", nullable: true,
                        properties: {
                            full_url: {type: "string"},
                            file_name: {type: "string"},
                        },
                        required: ["full_url", "file_name"],
                        additionalProperties: false
                    },
                    sentences: {
                        type: "array", nullable: true, minItems: 1,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                text: {type: "string"},
                                is_word: {type: "integer", minimum: 0, maximum: 1},
                                is_head: {type: "integer", minimum: 0, maximum: 1},
                            },
                            required: ["id", "text", "is_word", "is_head"],
                            additionalProperties: false
                        },
                    },
                    tags: {
                        type: "array", nullable: true,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                name: {type: "string"},
                                description: {type: "string"},
                                created_at: {type: "string", format: "date-time"},
                            },
                            required: ["id", "name", "description", "created_at"],
                            additionalProperties: false
                        }
                    },
                    liebiaos: {
                        type: "array", nullable: true,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                name: {type: "string"},
                                description: {type: "string"},
                                created_at: {type: "string", format: "date-time"}
                            },
                            required: ["id", "name", "description", "created_at"],
                            additionalProperties: false
                        }
                    }
                },
                required: ["id", "created_at", "verdict", "no_quiz"]
            }
        }
    },
    required: ["finished_at", "quiz_id", "started_at", "total", "verdicts", "wrong", "right", "duration"]
};

const recently_quizzed_data_schema: JSONSchemaType<RecentlyQuizzedData> = {
    $id: "shengci.com/schemas/recently_quizzed_data.json",
    type: "object",
    properties: {
        verdicts: {type: "array", nullable: false,
            items: {
                type: "object",
                properties: {
                    id: {type: "integer", minimum: 1},
                    created_at: {type: "string", nullable: false},
                    last_quiz: {type: "string", nullable: true},
                    status: {type: "string", nullable: true},
                    new_status: {type: "string", nullable: true},
                    verdict: {type: "boolean", nullable: true},
                    pinyin: {type: "string", nullable: true},
                    no_quiz: {type: "boolean", nullable: false},
                    note: {
                        type: "object", nullable: true,
                        properties: {
                            id: {type: "integer", minimum: 1},
                            content: {type: "string"},
                        },
                        required: ["id", "content"],
                        additionalProperties: false
                    },
                    audio_file: {
                        type: "object", nullable: true,
                        properties: {
                            full_url: {type: "string"},
                            file_name: {type: "string"},
                        },
                        required: ["full_url", "file_name"],
                        additionalProperties: false
                    },
                    sentences: {
                        type: "array", nullable: true, minItems: 1,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                text: {type: "string"},
                                is_word: {type: "integer", minimum: 0, maximum: 1},
                                is_head: {type: "integer", minimum: 0, maximum: 1},
                            },
                            required: ["id", "text", "is_word", "is_head"],
                            additionalProperties: false
                        },
                    },
                    tags: {
                        type: "array", nullable: true,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                name: {type: "string"},
                                description: {type: "string"},
                                created_at: {type: "string", format: "date-time"},
                            },
                            required: ["id", "name", "description", "created_at"],
                            additionalProperties: false
                        }
                    },
                    liebiaos: {
                        type: "array", nullable: true,
                        items: {
                            type: "object",
                            properties: {
                                id: {type: "integer", minimum: 1},
                                name: {type: "string"},
                                description: {type: "string"},
                                created_at: {type: "string", format: "date-time"}
                            },
                            required: ["id", "name", "description", "created_at"],
                            additionalProperties: false
                        }
                    }
                },
                required: ["id", "created_at", "verdict", "no_quiz"]
            }
        }
    },
    required: ["verdicts"]
};

const ajv = new Ajv();
addFormats(ajv);

const new_quiz_validator = ajv.compile<NewQuizResult>(new_quiz_data_schema);
const quiz_entry_result_validator = ajv.compile<QuizEntryResultData>(quiz_entry_result_data_schema);
const quiz_resume_result_validator = ajv.compile<QuizResumeResultData>(quiz_resume_result_data_schema);
const quiz_summary_result_validator = ajv.compile<QuizSummaryResultData & {quiz_id: number}>(quiz_summary_result_data_schema);
const recently_quizzed_validator = ajv.compile<RecentlyQuizzedData>(recently_quizzed_data_schema);

@injectable()
export class QuizServiceImp implements QuizService
{
    private readonly hanasu: Hanasu;
    private readonly new_quiz_url: string = "api/quiz/create";
    private readonly next_entry_url: string = "api/quiz/get_entry";
    private readonly recently_quizzed_url: string = "api/quiz/recently_quizzed";
    private readonly summary_url: string = "api/quiz/summary";
    private readonly resume_url: string = "api/quiz/resume";
    
    public constructor(
        @inject(TYPES.HANASU_INSTANCE) _hanasu: Hanasu,
    ) {
        this.hanasu = _hanasu;
    }

    getRecentlyQuizzed(): Promise<Array<QuizSummaryEntryVerdict>> {
        return new Promise((resolve, reject) => {
            const post_sub: Subscription = this.hanasu.postBuffered<any,RecentlyQuizzedData>(
                this.recently_quizzed_url,
                {}
            ).subscribe((response: AxiosResponse<RecentlyQuizzedData>) => {
                post_sub.unsubscribe();
                if(process.env.VUE_APP_DEBUG) {
                    if(!recently_quizzed_validator(response.data)) {
                        throw new ValidationFailed(recently_quizzed_validator.errors);
                    }
                }
                const verdicts: Array<QuizSummaryEntryVerdict> = new Array<QuizSummaryEntryVerdict>();
                response.data.verdicts.forEach( (vocabulary_data_verdict: VocabularyDataVerdict) => {
                    verdicts.push({
                        vocabulary: (this.makeQuizEntry(vocabulary_data_verdict)).vocabulary,
                        verdict: vocabulary_data_verdict.verdict,
                        new_status: vocabulary_data_verdict.new_status
                    } as QuizSummaryEntryVerdict);
                });
                resolve(verdicts);
            });
        });
    }

    resumeQuiz(quiz_item_request: QuizEntryRequest): Promise<QuizResumeResult> {
        return new Promise((resolve, reject) => {
            const post_sub: Subscription = this.hanasu.postBuffered<QuizEntryRequest,QuizResumeResultData>(
                this.resume_url,
                quiz_item_request,
            ).subscribe((response: AxiosResponse<QuizResumeResultData>) => {
                post_sub.unsubscribe();
                if(process.env.VUE_APP_DEBUG) {
                    if(!quiz_resume_result_validator(response.data)) {
                        throw new ValidationFailed(quiz_resume_result_validator.errors);
                    }
                    if(!response.data.quiz_entry && !response.data.quiz_summary) {
                        throw new QuizResumeResultError();
                    }
                }
                const result: QuizResumeResult = {
                    quiz_id: response.data.quiz_id,
                    total: response.data.quiz_total,
                    quiz_settings: {
                        reverse_mode: response.data.quiz_settings.reverse_mode,
                        audio_mode: response.data.quiz_settings.audio_mode
                    }
                }
                if(response.data.quiz_index) {
                    result.quiz_index = response.data.quiz_index; 
                }
                if(response.data.quiz_entry) {
                    result.entry = this.makeQuizEntry(response.data.quiz_entry); 
                }
                if(response.data.quiz_summary) {
                    const verdicts: Array<QuizSummaryEntryVerdict> = new Array<QuizSummaryEntryVerdict>();
                    response.data.quiz_summary.verdicts.forEach( (vocabulary_data_verdict: VocabularyDataVerdict) => {
                        verdicts.push({
                            vocabulary: (this.makeQuizEntry(vocabulary_data_verdict)).vocabulary,
                            verdict: vocabulary_data_verdict.verdict,
                            new_status: vocabulary_data_verdict.new_status
                        } as QuizSummaryEntryVerdict);
                    });
                    const quiz_summary_result: QuizSummaryResult = {
                        total: response.data.quiz_summary.total,
                        right: response.data.quiz_summary.right,
                        wrong: response.data.quiz_summary.wrong,
                        started_at: response.data.quiz_summary.started_at,
                        finished_at: response.data.quiz_summary.finished_at,
                        duration: response.data.quiz_summary.duration,
                        verdicts: verdicts
                    };
                    result.quiz_summary = quiz_summary_result;
                }

                resolve(result);
            });
        });
    }

    getQuizSummary(quiz_summary_request: QuizSummaryRequest): Promise<QuizSummaryResult> {
        return new Promise((resolve, reject) => {
            const post_sub: Subscription = this.hanasu.postBuffered<QuizSummaryRequest, QuizSummaryResultData & {quiz_id: number}>(
                this.summary_url,
                quiz_summary_request,
            ).subscribe((response: AxiosResponse< QuizSummaryResultData & {quiz_id: number}>) => {
                post_sub.unsubscribe();
                if(process.env.VUE_APP_DEBUG) {
                    if(!quiz_summary_result_validator(response.data)) {
                        throw new ValidationFailed(quiz_summary_result_validator.errors);
                    }
                }
                const verdicts: Array<QuizSummaryEntryVerdict> = new Array<QuizSummaryEntryVerdict>();
                response.data.verdicts.forEach( (vocabulary_data_verdict: VocabularyDataVerdict) => {
                    const summary_entry_verdict: QuizSummaryEntryVerdict = {
                        vocabulary: (this.makeQuizEntry(vocabulary_data_verdict)).vocabulary,
                        verdict: vocabulary_data_verdict.verdict,
                    };
                    if(vocabulary_data_verdict.new_status) summary_entry_verdict.new_status = getStatusString(vocabulary_data_verdict.new_status);
                    verdicts.push(summary_entry_verdict);
                });
                const quiz_summary_result: QuizSummaryResult = {
                    total: response.data.total,
                    right: response.data.right,
                    wrong: response.data.wrong,
                    started_at: response.data.started_at,
                    finished_at: response.data.finished_at,
                    duration: response.data.duration,
                    verdicts: verdicts
                };
                resolve(quiz_summary_result);
            })
        });
    }

    getEntry(quiz_item_request: QuizEntryRequest): Promise<QuizEntryResult> {
        return new Promise((resolve, reject) => {
            const post_sub: Subscription = this.hanasu.postBuffered<QuizEntryRequest,QuizEntryResultData>(
                this.next_entry_url,
                quiz_item_request,
            ).subscribe((response: AxiosResponse<QuizEntryResultData>) => {
                post_sub.unsubscribe();
                if(process.env.VUE_APP_DEBUG) {
                    if(!quiz_entry_result_validator(response.data)) {
                        throw new ValidationFailed(quiz_entry_result_validator.errors);
                    }
                }
                const result: QuizEntryResult = {
                    quiz_id: response.data.quiz_id,
                    total: response.data.total,
                    //entry: this.makeQuizEntry(response.data.entry),
                    //quiz_index: response.data.quiz_index  
                }
                if(response.data.entry) {
                    result.entry = this.makeQuizEntry(response.data.entry);
                }
                if(response.data.quiz_index) {
                    result.quiz_index = response.data.quiz_index;
                }
                resolve(result);
            });
        });
    }

    create(quiz_request: NewQuizData): Promise<NewQuizResult> {
        return new Promise((resolve, reject) => {
            const post_sub: Subscription = this.hanasu.postBuffered<NewQuizData,NewQuizResultData>(
                this.new_quiz_url,
                quiz_request,
                undefined,
                WAIT_TIMES.SLOW
            ).subscribe((response: AxiosResponse<NewQuizResultData>) => {
                post_sub.unsubscribe();
                if(process.env.VUE_APP_DEBUG) {
                    if(!new_quiz_validator(response.data)) {
                        throw new ValidationFailed(new_quiz_validator.errors);
                    }
                }
                const result: NewQuizResult = {
                    quiz_id: response.data.quiz_id,
                    total: response.data.total,
                    first: this.makeQuizEntry(response.data.first)
                }
                resolve(result);
            });
        });
    }

    public makeQuizEntry(vocabulary_data: VocabularyData): QuizEntry {
        const vocabulary_tags: Array<Tag> = new Array<Tag>();
        vocabulary_data.tags?.forEach((tag_data: {id:number, name:string, description: string, created_at: string}) => {
            vocabulary_tags.push({
                id: tag_data.id,
                name: tag_data.name,
                description: tag_data.description,
                created_at: new Date(tag_data.created_at)
            });
        });
        let head: Sentence = {id:0, text:"", is_word:false};
        const other_sentences: Array<Sentence> = new Array<Sentence>();
        vocabulary_data.sentences?.forEach( (sentence_data: SentenceData) => {
            if(sentence_data.is_head) {
                head = {id:sentence_data.id, text:sentence_data.text, is_word:Boolean(sentence_data.is_word)};
            } else {
                other_sentences.push({id:sentence_data.id, text:sentence_data.text, is_word:Boolean(sentence_data.is_word)});
            }
        });
        const vocabulary: Vocabulary = {
            id: vocabulary_data.id,
            no_quiz: vocabulary_data.no_quiz,
            head: head,
            created_at: vocabulary_data.created_at,
            pinyin: vocabulary_data.pinyin ?? "",
            audio_file: new AppURL(vocabulary_data.audio_file?.full_url ?? "", false, vocabulary_data.audio_file?.file_name ?? ""),
            sentences: other_sentences,
            tags: vocabulary_tags,
            note: vocabulary_data.note ?? {id:0, content:""},
            //liebiaos: vocabulary_data.liebiaos ?? new Array<Liebiao>(),
        }
        if(vocabulary_data.status) {
            vocabulary.status = getStatusString(vocabulary_data.status);
        }
        if(vocabulary_data.last_quiz) {
            vocabulary.last_quiz = vocabulary_data.last_quiz;
        }
        const vocabulary_liebiao: Array<Liebiao> = new Array<Liebiao>();
        if(vocabulary_data.liebiaos) {
            vocabulary_data.liebiaos.forEach( (liebiao: {id:number, name:string, description: string, created_at: string}) => {
                vocabulary_liebiao.push({
                    id: liebiao.id,
                    name: liebiao.name,
                    description: liebiao.description,
                    created_at: new Date(liebiao.created_at)
                });
            });
            vocabulary.liebiaos = vocabulary_liebiao;
        }
        return {
            vocabulary: vocabulary
        } as QuizEntry;
    }

    
}
