import { compareComponents } from '@/helpers/helpers';
import {Stringable} from '@/types';
import AppMain from '@/AppMain';

export class AppURL implements Stringable
{
    public static BASE_URL: string = process.env.VUE_APP_URL;
    public full_url: string= "";
    public relative_url: string = "";
    public is_file: boolean = false;
    public file_name: string= "";

    constructor(url: string, is_relative: boolean = true, file_name?: string) {
        if(is_relative) {
            this.relative_url = url;
            this.full_url = AppURL.BASE_URL.concat(this.removeSlashes(url));
        } else {
            this.full_url = url;
            this.relative_url = this.removeBaseURL(url);
        }
        if(file_name) this.file_name =  file_name;
    }

    getFileName(): string {
        return this.file_name;
    }

    removeBaseURL(str: string): string {
        return str.replace(AppURL.BASE_URL, '');
    }

    removeSlashes(str: string): string {
        return (str.replace(/\/+$/, '')).replace(/^\/+/, '');
    }

    asString(): string {
        return this.full_url;
    }

    getRelativeURL(): string {
        return this.relative_url;
    }

    getFullURL(): string {
        return this.full_url;
    }
    
    isEmpty(): boolean {
        return !(this.relative_url.length > 0);
    }
}