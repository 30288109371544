import { SortParams, Tag, PaginateParams, Nullable, VocabulayStateStoreRequest, NewVocabularyData, VocabularyDeleteRequest, SearchParams, Liebiao, VocabularyStatus } from '@/types';
import { VOCABULARY_SORT_COLUMN, VOCABULARY_TEXT_SEARCH_COLUMN } from '@/enums';
import { log } from '@/helpers/logger';


export class VocabularyQueryParams
{
    public sort_params: SortParams = {
        column: VOCABULARY_SORT_COLUMN.DATE_CREATED,
        order: "desc"
    };

    public paginate_params: PaginateParams = {
        page: 0,
        page_size: 10
    };

    public id: number = 0; //used for comparing different instances
    public state_store_request: Nullable<VocabulayStateStoreRequest> = null;
    public store_request: Nullable<NewVocabularyData> = null;
    public delete_request: Nullable<VocabularyDeleteRequest> = null;
    public search_params: Nullable<SearchParams> = null;
    public is_refresh: boolean = false; //true when this page request is actually for a refresh
    public trigger_loader: boolean = false;
    public audio_file: Nullable<File> = null;
    public invalidate_buffer: boolean = false;

    public constructor(sort_params?: SortParams, paginate_params?: PaginateParams, search_params?: SearchParams, state_store_request?: VocabulayStateStoreRequest) {
        if(sort_params) this.sort_params = sort_params;
        if(paginate_params) this.paginate_params = paginate_params;
        if(search_params) this.search_params = search_params;
        if(state_store_request) this.state_store_request = state_store_request;
    }

    generateID(): void {
        this.id = Math.floor(Math.random() * 1000000);
    }

    cleanParams(): void {
        this.state_store_request = null;
        this.store_request = null;
        this.is_refresh = false;
        this.audio_file = null;
        this.delete_request = null;
        this.search_params = null;
        this.trigger_loader = false;
        this.invalidateCurrentBuffer(false);
    }

    public addSearchParams(search_params: SearchParams): void {
        //search params need to be cloned, as the reference is probably a live reactive object used by other vue widgets
        this.search_params = {
            text: search_params.text,
            mode: search_params.mode,
            search_column: search_params.search_column,
            regexp: search_params.regexp,
            date_to: search_params.date_to,
            date_from: search_params.date_from,
            tags_include: new Array<Tag>(),
            tags_exclude: new Array<Tag>(),
            liebiaos: new Array<Liebiao>(),
            statuses: new Array<VocabularyStatus>(),
        };
        //dont need to clone tags right?
        search_params.tags_include.forEach((tag: Tag) => {
            this.search_params?.tags_include.push(tag);
        });
        search_params.tags_exclude.forEach((tag: Tag) => {
            this.search_params?.tags_exclude.push(tag);
        });
        search_params.liebiaos.forEach((liebiao: Liebiao) => {
            this.search_params?.liebiaos.push(liebiao);
        });
        search_params.statuses.forEach((status: VocabularyStatus) => {
            this.search_params?.statuses.push(status);
        });
    }

    public pruneForBufferQuery(): void {
        this.store_request = null;
        this.delete_request = null;
        this.state_store_request = null;
        this.is_refresh = false;
        this.audio_file = null;
        this.invalidateCurrentBuffer(false);
    }

    public addDeleteRequest(delete_request: VocabularyDeleteRequest): void {
        this.is_refresh = true;
        this.trigger_loader = false;
        this.delete_request = delete_request;
    }

    public addStoreRequest(store_request: NewVocabularyData): void {
        this.is_refresh = true;
        this.trigger_loader = false;
        this.store_request = store_request;
        if(store_request.audio_file) this.audio_file = store_request.audio_file;
        /*
        if(store_request.id) this.store_request.id = store_request.id;
        if(store_request.pinyin) this.store_request.pinyin = store_request.pinyin;
        //dont clone the file (too much memory, and we dont need it anyways)
        //if(this.store_request.audio_file) clone.store_request.audio_file = this.store_request.audio_file;
        if(store_request.tranlsations) {
            this.store_request.tranlsations = new Array<string>();
            store_request.tranlsations.forEach((translation: string) => {
                if(this.store_request && this.store_request.tranlsations && store_request.tranlsations) this.store_request.tranlsations.push(translation);
            });
        }
        if(store_request.tags) {
            this.store_request.tags = new Array<string>();
            store_request.tags.forEach((tag: string) => {
                if(this.store_request && this.store_request.tags && store_request.tags) this.store_request.tags.push(tag);
            });
        }
        if(store_request.audio_file) {
            this.store_request.audio_file = new File([store_request.audio_file], store_request.audio_file.name, { type: store_request.audio_file.type });
        }
        */
    }

    public addStateStoreRequest(state_store_request: VocabulayStateStoreRequest): void {
        this.state_store_request = state_store_request;
    }

    public setPage(page: number): void {
        this.paginate_params.page = page;
    }

    public invalidateCurrentBuffer(invalidates: boolean): void {
        //console.log(`setting invalidate buffer: ${invalidates}`);
        this.invalidate_buffer = invalidates;
    }

    //if this query should invalidate the buffer
    public invalidatesCurrentBuffer(): boolean {
        return this.invalidate_buffer;
        /*
        if(!!this.search_params !== !!vocabulary_query_params.search_params) return true;
        if(this.search_params && vocabulary_query_params.search_params) {
            if(this.search_params.mode !== vocabulary_query_params.search_params.mode) return true;
            if(this.search_params.mode == "simple") {
                if(this.search_params.text !== vocabulary_query_params.search_params.text) return true;
            } else if(this.search_params.mode == "advanced") {
                return true; //advanced searches always empty out the buffer
            }
        }
        if( this.sort_params.column !== vocabulary_query_params.sort_params.column || 
            this.sort_params.order !== vocabulary_query_params.sort_params.order ||
            this.paginate_params.page_size !== vocabulary_query_params.paginate_params.page_size) {
            return true;
        }
        return false;
        */
    }

    
    public isIdentical(vocabulary_query_params: VocabularyQueryParams): boolean {
        return this.id == vocabulary_query_params.id;
        /*
        if(!!this.search_params !== !!vocabulary_query_params.search_params) {
            //console.log('isSameAs: here1');
            //log(this.search_params);
            //log(vocabulary_query_params.search_params);
            return false;
        }
        if(this.search_params && vocabulary_query_params.search_params) {
            if(this.search_params.mode == "simple" && vocabulary_query_params.search_params.mode == "simple") {
                if(this.search_params.text !== vocabulary_query_params.search_params.text) { 
                    //console.log('isSameAs: here2');
                    return false;
                }
            } else if(this.search_params.mode == "advanced" && vocabulary_query_params.search_params.mode == "advanced") {
                //console.log('isSameAs: here3');
                return false;
            }
        }

        const is_same_as: boolean =  this.paginate_params.page == vocabulary_query_params.paginate_params.page && 
            this.paginate_params.page_size == vocabulary_query_params.paginate_params.page_size && 
            this.sort_params.column == vocabulary_query_params.sort_params.column && 
            this.sort_params.order == vocabulary_query_params.sort_params.order;
        //log(`is_same_as: ${is_same_as}`);
        return is_same_as;
        */
    }

    public clone(): VocabularyQueryParams {
        const clone: VocabularyQueryParams = new VocabularyQueryParams();
        clone.id = this.id;
        clone.sort_params.column = this.sort_params.column;
        clone.sort_params.order = this.sort_params.order;
        clone.paginate_params.page = this.paginate_params.page;
        clone.paginate_params.page_size = this.paginate_params.page_size;
        clone.is_refresh = this.is_refresh;
        clone.invalidate_buffer = this.invalidate_buffer;
        if(this.search_params) {
            clone.search_params = {
                mode: this.search_params.mode,
                text: "",
                search_column: this.search_params.search_column,
                date_from: null,
                date_to: null,
                regexp: this.search_params.regexp,
                tags_include: new Array<Tag>(),
                tags_exclude: new Array<Tag>(),
                liebiaos: new Array<Liebiao>(),
                statuses: new Array<VocabularyStatus>(),
            };
            if(this.search_params.text && this.search_params.text.length > 0) clone.search_params.text = this.search_params.text;
            if(this.search_params.date_from) clone.search_params.date_from = this.search_params.date_from;
            if(this.search_params.date_to) clone.search_params.date_to = this.search_params.date_to;
            if(this.search_params.tags_include.length > 0) {
                this.search_params.tags_include.forEach((tag: Tag) => clone.search_params?.tags_include?.push(tag))
            }
            if(this.search_params.tags_exclude.length > 0) {
                this.search_params.tags_exclude.forEach((tag: Tag) => clone.search_params?.tags_exclude?.push(tag))
            }
            if(this.search_params.liebiaos.length > 0) {
                this.search_params.liebiaos.forEach((liebiao: Liebiao) => clone.search_params?.liebiaos?.push(liebiao))
            }
            if(this.search_params.statuses.length > 0) {
                this.search_params.statuses.forEach((status: VocabularyStatus) => clone.search_params?.statuses?.push(status))
            }
        }
        if(this.state_store_request) {
            clone.state_store_request = {
                query_id: this.state_store_request.query_id
            };
            if(this.state_store_request.all) {
                clone.state_store_request.all = true;
            }
            if(this.state_store_request.none) {
                clone.state_store_request.none = true;
            }
        }
        if(this.store_request) {
            clone.store_request = {
                head: this.store_request.head,
                no_quiz: this.store_request.no_quiz
            };
            if(this.store_request.id) clone.store_request.id = this.store_request.id;
            if(this.store_request.pinyin) clone.store_request.pinyin = this.store_request.pinyin;
            if(this.store_request.note) clone.store_request.note = this.store_request.note;
            //dont clone the file, just copy the ref
            if(this.audio_file) clone.store_request.audio_file = this.audio_file;
            if(this.store_request.translations) {
                clone.store_request.translations = new Array<string>();
                this.store_request.translations.forEach((translation: string) => {
                    if(clone.store_request?.translations) clone.store_request.translations.push(translation);
                });
            }
            if(this.store_request.tags) {
                clone.store_request.tags = new Array<string>();
                this.store_request.tags.forEach((tag: string) => {
                    if(clone.store_request?.tags) clone.store_request.tags.push(tag);
                });
            }
            if(this.store_request.liebiaos) {
                clone.store_request.liebiaos = new Array<number>();
                this.store_request.liebiaos.forEach((liebiao_id: number) => {
                    if(clone.store_request?.liebiaos) clone.store_request.liebiaos.push(liebiao_id);
                });
            }
            if(this.store_request.status) {
                clone.store_request.status = this.store_request.status
            }
        }
        if(this.delete_request) {
            clone.delete_request = {
                query_id: this.delete_request.query_id,
            };
            if(this.delete_request.vocabulary_ids && this.delete_request.vocabulary_ids.length > 0) {
                clone.delete_request.vocabulary_ids = new Array<number>();
                this.delete_request.vocabulary_ids.forEach((vocabulary_id: number) => {
                    if(clone.delete_request?.vocabulary_ids) clone.delete_request.vocabulary_ids.push(vocabulary_id);
                });
            }
            if(typeof this.delete_request.selected !== 'undefined') clone.delete_request.selected = this.delete_request.selected;
        }
        return clone;
    }

    cloneSearchParams(search_params?: SearchParams): Nullable<SearchParams> {
        if(search_params) {
            return VocabularyQueryParams.staticCloneSearchParams(search_params);
        } else if(this.search_params) {
            return VocabularyQueryParams.staticCloneSearchParams(this.search_params);
        }
        return null;
    }
    
    static staticCloneSearchParams(source_search_params: SearchParams): SearchParams {
        const clone: SearchParams = {
            mode: source_search_params.mode,
            text: "",
            search_column: source_search_params.search_column,
            date_from: null,
            date_to: null,
            regexp: source_search_params.regexp,
            tags_include: new Array<Tag>(),
            tags_exclude: new Array<Tag>(),
            liebiaos: new Array<Liebiao>(),
            statuses: new Array<VocabularyStatus>(),
        };
        if(source_search_params.text && source_search_params.text.length > 0) clone.text = source_search_params.text;
        if(source_search_params.date_from) clone.date_from = source_search_params.date_from;
        if(source_search_params.date_to) clone.date_to = source_search_params.date_to;
        if(source_search_params.tags_include.length > 0) {
            source_search_params.tags_include.forEach((tag: Tag) => clone?.tags_include?.push(tag))
        }
        if(source_search_params.tags_exclude.length > 0) {
            source_search_params.tags_exclude.forEach((tag: Tag) => clone?.tags_exclude?.push(tag))
        }
        if(source_search_params.liebiaos.length > 0) {
            source_search_params.liebiaos.forEach((liebiao: Liebiao) => clone?.liebiaos?.push(liebiao))
        }
        if(source_search_params.statuses.length > 0) {
            source_search_params.statuses.forEach((status: VocabularyStatus) => clone?.statuses?.push(status))
        }
        return clone;
    }
}