import {Nullable} from '@/types'

export class BrowserCookie
{
    public name: string = "";
    public value: string = "";
    public expires?: Nullable<Date> = null;

    constructor(name: Nullable<string>, value: Nullable<string>, expires: Nullable<Date>) {
        if(name) this.name = name;
        if(value) this.value = value; 
        if(expires) this.expires = expires;
    }
}