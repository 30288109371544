import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import {Authenticator, ModuleProvider, NavRouter} from '@/services';
import {TYPES} from '@/ioc/types';
import container from "@/ioc/inversify.config";
//import {SHENGCI_MODULES} from '@/enums';
import { AppStage} from '@/types';
import { ReplaySubject, Subject } from 'rxjs';
import { SHENGCI_MODULES, ROUTER_EVENT } from '@/enums';

//this class is the composition root for services. do not use the inversify container anywhere else to resolve dependencies
//https://blog.ploeh.dk/2010/02/03/ServiceLocatorisanAnti-Pattern/
//https://martinfowler.com/articles/injection.html
class AppMain
{
  public vue_app: Vue;
  public mount_element_id: string = '#app';

  private authenticator!: Authenticator;
  private module_provider!: ModuleProvider;
  private nav_router!: NavRouter;
  private last_visible_datetime: Date = new Date(Date.now());
  private app_stage: AppStage = "loading";
  private tab_lifetime: number = 1000 * 60 * 60 * 2;

  public readonly app_stage_events$: ReplaySubject<AppStage> = new ReplaySubject<AppStage>(1);
  public readonly keydown_events$: Subject<Event> = new Subject<Event>();

  constructor() {
    this.module_provider = container.get<ModuleProvider>(TYPES.MODULE_PROVIDER_INSTANCE);
    this.authenticator = container.get<Authenticator>(TYPES.AUTHENTICATOR_INSTANCE);
    this.nav_router = container.get<NavRouter>(TYPES.NAVROUTER_INSTANCE);
    this.last_visible_datetime = new Date(Date.now());
    //process.env.NODE_ENV == 'development'

    this.authenticator.regenerateSession();

    document.addEventListener("visibilitychange", () => { 
      if(document.visibilityState === 'visible') {
        this.tabResumed();
      }
    });

    window.onbeforeunload = () => {
        this.app_stage_events$.next("unloading");
        //return true;
    };

    //document.addEventListener('keydown', (event_key: Event) => this.OnKeyDown(event_key), false);
    router.beforeEach((to, from, next) => {
      this.nav_router.nav_event$.next({to: to, from: from});
      next();
    });

    //cancel the initial router nav if not authed
    /*
    router.beforeEach((to, from, next) => {
      if(!this.nav_router.navigatablesAreSame(to, SHENGCI_MODULES.LOGIN) && from.name == null && this.authenticator.auth_status !== "logged in" ) {
        console.log('canceling router nav: ');
        console.log(to)
        this.nav_router.onRouterReady();
        return false;
      }
      next();
    });
    */

    this.vue_app = new Vue({
      router,
      vuetify,
      render: h => h(App)
    });
    Vue.config.productionTip = false;
    this.nav_router.setVueInstance(this.vue_app);
  }

  /*
  private OnKeyDown(event_key: Event): void {
    this.keydown_events$.next(event_key);
  }
  */

  start() { 
    this.vue_app.$mount(this.mount_element_id);
  }

  tabResumed() {
    const current_datetime = new Date(Date.now());
    //if we've spent an hour away from the tab, validate sesison again
    if( ( current_datetime.getTime() - this.last_visible_datetime.getTime() ) > this.tab_lifetime) {
      this.authenticator.regenerateSession();
    }  
    this.last_visible_datetime = current_datetime;
  }

  setAppStage(new_app_stage: AppStage): AppStage {
    this.app_stage = new_app_stage;
    this.app_stage_events$.next(new_app_stage);
    return this.app_stage;
  }

  getAppStage(): AppStage {
    return this.app_stage;
  }

}

export default new AppMain();