import {injectable} from 'inversify';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import {Hanasu} from '@/services/Hanasu/Hanasu.inf'
import {Observable, Subscription, from, timer, combineLatest, defer, throwError, Subject, ReplaySubject, of} from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share} from 'rxjs/operators';
import {genericRetryStrategy} from '@/helpers/genericRetryStrategy';
import {WAIT_TIMES, WAIT_SCALING} from '@/enums';
import { AppURL } from '@/classes/AppURL';

@injectable()
export class HanasuImp implements Hanasu
{
    client: AxiosInstance;
    public readonly default_excluded_status_codes: Array<number> = [419, 422, 401, 404, 403, 500];

    constructor() {
        this.client = Axios.create({
          baseURL: AppURL.BASE_URL,
          timeout: 20000,
          withCredentials: true,
        });
    }

    /*
    fetch(): void {
      const new_form: FormData = new FormData();
      this.client.post('', new_form);
    }
    */

    /**
     * Issuess an unbuffered post request. Usually postBuffered() is a safer choice
     * @type {D} The type of the payload
     * @type {R} The type of the response
     * @param {string} url - The post route
     * @param {D} data - The payload
     * @returns {Observable<AxiosResponse<R>>}
     */
    post<D, R>(url: string, data: D): Observable<AxiosResponse<R>> {
        return from(this.client.post<D, AxiosResponse<R>, D>(url, data)).pipe(
          catchError( (error) => {
            console.log('caught an error here!')
            return throwError(error);
          })
        );
    }

    /**
     * Issuess an unbuffered get request. Usually getBuffered() is a safer choice
     * @type {R} The type of the response
     * @param {string} url - The post route
     * @returns {Observable<AxiosResponse<R>>}
     */
    get<R>(url: string): Observable<AxiosResponse<R>> {
        return from(this.client.get<R>(url));
    }

    /**
     * Issuess a get request, but will retry if request fails. Will also optionally dalay the response by a minimum time
     * @type {R} The type of the response
     * @param {string} url - The post route
     * @param {number[]} excluded_status_codes - Status codes that will not trigger a retry e.g.
     * @param {WAIT_TIMES} minimum_time - Minimum time to delay
     * @returns {Observable<AxiosResponse<R>>}
     */
     public getBuffered<R>(url: string, excluded_status_codes: Array<number> = this.default_excluded_status_codes, minimum_time?: WAIT_TIMES): Observable<AxiosResponse<R>> {
      const start_time = new Date().getTime();
      const server_response$ = from( defer( () => this.get<R>(url) ) ).pipe(
          retryWhen(genericRetryStrategy({
              scalingDuration: WAIT_SCALING.INFREQUENT,
              excludedStatusCodes: excluded_status_codes
            })),
            catchError(error => {
              if(minimum_time) {
                const remaining_time = (start_time + minimum_time) - (new Date().getTime());
                if(remaining_time <= 0) {
                    return throwError(error);
                } else {
                    return timer(remaining_time).pipe(take(1), switchMap(() => throwError(error)));
                }
              } else {
                return throwError(error);
              }
            })
      );

      if(minimum_time) {
        const delay_timer$ = timer(minimum_time).pipe(take(1), share());
        const buffered_response$ = combineLatest(delay_timer$, server_response$);
        return buffered_response$.pipe(map( (value) => {
            return value[1] as AxiosResponse<R>;
        }));
      } else {
        return server_response$;
      }
    }

    /**
     * Issuess a post request, but will retry if request fails. Will also optionally dalay the response by a minimum time
     * @type {D} The type of the payload
     * @type {R} The type of the response
     * @param {string} url - The post route
     * @param {D} data - The payload
     * @param {number[]} excluded_status_codes - Status codes that will not trigger a retry e.g.
     * @param {WAIT_TIMES} minimum_time - Minimum time to delay
     * @returns {Observable<AxiosResponse<R>>}
     */
    public postBuffered<D,R>(url: string, data: D, excluded_status_codes:  Array<number> = this.default_excluded_status_codes, minimum_time?: WAIT_TIMES): Observable<AxiosResponse<R>> {
      const start_time = new Date().getTime();
      const server_response$ = from( defer( () => this.post<D, R>(url, data) ) ).pipe(
          retryWhen(genericRetryStrategy({
              scalingDuration: WAIT_SCALING.INFREQUENT,
              excludedStatusCodes: excluded_status_codes
            })),
            catchError(error => {
              console.log('postBuffered catching error!');
              if(minimum_time) {
                const remaining_time = (start_time + minimum_time) - (new Date().getTime());
                if(remaining_time <= 0) {
                    return throwError(error);
                } else {
                    return timer(remaining_time).pipe(take(1), switchMap(() => throwError(error)));
                }
              } else {
                return throwError(error);
              }
            })
      );

      if(minimum_time) {
        const delay_timer$ = timer(minimum_time).pipe(take(1), share());
        const buffered_response$ = combineLatest(delay_timer$, server_response$);
        return buffered_response$.pipe(map( (value) => {
            return value[1] as AxiosResponse<R>;
        }));
      } else {
        return server_response$;
      }
    }
}

/*
export class Axios {
  constructor(config?: AxiosRequestConfig);
  defaults: AxiosDefaults;
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<R>;
  get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  head<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  options<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
  patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>;
}
*/