export const enum SHENGCI_MODULES {
    LOGIN = 'login',
    LOGOUT = 'logout',
    HOME = 'home',
    VOCABULARY = 'vocabulary',
    TAGS = 'tags',
    LIEBIAO = 'liebiaos',
    QUIZ = 'quiz',
    SUMMARY = 'summary',
    SETTINGS = 'settings',
    ACHIEVEMENTS = 'achievements',
    NONE = 'NONE',
}

export const enum WAIT_TIMES {
    SLOWEST = 1200,
    SLOWER = 1000,
    SLOW = 800,
    FAST = 500,
    FASTER = 350,
    FASTEST = 200,
}

export const enum WAIT_SCALING {
    FREQUENT = 800,
    INFREQUENT = 1800,
}

export const enum STORAGE_KEY_NAMES {
    SESSION_EXPIRATION_KEY = 'key:session_expiration',
    USER_KEY = 'key:user',
}

export const enum COOKIE_NAMES {
    LARAVEL_SESSION = 'laravel_session',
    XSRF_TOKEN = 'XSRF-TOKEN',
    SESSION_EXPIRES = 'session_expires',
}

export const enum APP_MODE {
    ONLINE = 0,
    OFFLIE = 1
}

export const enum VUE_LIFECYCLE_EVENT {
    BEFORE_CREATE = 0,
    CREATED = 1,
    BEFORE_MOUNT = 2,
    MOUNTED = 3,
    ACTIVATED = 4,
    BEFORE_UPDATE = 5,
    UPDATED = 6,
    UNMOUNTED = 7,
}

export const enum ROUTER_EVENT {
    NAVIGATION_FAIL = 0,
    NAVIGATION_SUCCESS = 1,
    NAVIGATION_REDIRECT = 2,
}

export const enum VOCABULARY_SELECTION_OPTION {
    SELECT_ALL = 0,
    SELECT_NONE = 1,
    INVERT_SELECTION = 2,
    RANDOM_SELECTION = 3
}

export const enum QUIZ_SELECTION_OPTION {
    WORDS_10 = 1,
    WORDS_20 = 2,
    WORDS_50 = 3,
    WORDS_100 = 4,
    WORDS_150 = 5,
    WORDS_200 = 6,
    WORDS_300 = 7,
    RECENTLY_ADDED = 8,
    DIFFICULT = 9,
    DAILY = 10,
    WEEKLY = 11,
    RANDOM = 12
}

export const enum VOCABULARY_SORT_COLUMN {
    DATE_CREATED = 1,
    CHINESE = 2,
    PINYIN = 3,
    ENGLISH = 4,
    TIMES_QUIZZED = 5,
    LAST_QUIZZED = 6,
}

export const enum VOCABULARY_TEXT_SEARCH_COLUMN {
    CHINESE = 1,
    PINYIN = 2,
    ENGLISH = 3,
    NOTE = 4,
}