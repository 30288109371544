import Ajv, {JSONSchemaType} from "ajv";
import addFormats from "ajv-formats";
import { QueryIDChanged } from '@/exceptions';
import { Sentence, Note, Liebiao } from '@/types';
import { AppURL } from '@/classes/AppURL';
import { MessageDispatcher } from '@/services';
import { PageIndex, PageBufferMap, VocabularyPageResult, VocabularyState, Nullable, VocabularySelectionState, VocabulayStateStoreRequest, NotificationManager, VocabularyManager } from '@/types';
import { VocabularyEvent, VocabularyPageRequest, BufferedPage } from '@/types';
import {from, of, Observable, concat, Subject, throwError, Subscription, asyncScheduler, timer } from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share, takeUntil, retry, tap, mergeMap, delay, concatMap, debounceTime, throttleTime, mapTo, filter, mergeAll} from 'rxjs/operators';
import Axios, {AxiosInstance, AxiosResponse} from 'axios';
import pull from 'lodash/pull';
import { VocabularyService } from '@/services';
import { VocabularyQueryParams } from '@/classes/VocabularyQueryParams';
import { AppNotification } from '@/classes/AppNotification';
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";
import { log } from '@/helpers/logger';
import { compareComponents } from '@/helpers/helpers';


export class VocabularyQuery
{
    private message_dispatcher: MessageDispatcher;
    static NULL_QUERY_ID: string = "0";
    public query_params: VocabularyQueryParams = new VocabularyQueryParams();
    public query_id: string = VocabularyQuery.NULL_QUERY_ID;
    public total_vocabularies: number = 0;
    public max_page: PageIndex = 0;
    public page_buffer: PageBufferMap = new Map<PageIndex, BufferedPage>();
    public left_buffer_size: number = 1; //number of preceeding pages we buffer
    public right_buffer_size: number = 3; //number of following pages we buffer
    public page_requests$: Subject<VocabularyQueryParams> = new Subject<VocabularyQueryParams>();
    public page_results$: Subject<VocabularyPageResult> = new Subject<VocabularyPageResult>();
    public pending_pages: Array<PageIndex> = new Array<PageIndex>();
    private vocabulary_service: VocabularyService;
    public has_edited_selection: boolean = false;
    public owner: Nullable<VocabularyManager> = null; //the parent component who owns this notification
    public query_id_change: boolean = false;
    public needed_pages: Array<PageIndex> = new Array<PageIndex>();

    public get current_page(): PageIndex {
        return this.query_params.paginate_params.page;
    }

    public hasQueryID(): boolean {
        return this.query_id !== VocabularyQuery.NULL_QUERY_ID;
    }

    public constructor(_vocabulary_service: VocabularyService, _message_dispatcher: MessageDispatcher, owner?: Nullable<VocabularyManager>) {
        this.vocabulary_service = _vocabulary_service;
        this.message_dispatcher = _message_dispatcher;
        if(owner) this.owner = owner;

        this.page_requests$.subscribe( (query_params: VocabularyQueryParams) => {
            of(query_params).pipe(
                //make the first request or get from buffer
                switchMap((query_params: VocabularyQueryParams) => {
                    //if its a refresh or sorting params have changed, then need to flush buffer and rebuffer everything
                    //console.log('page request received:');
                    //log(query_params);
                    let buffer_flushed: boolean = false;
                    if(this.hasQueryID()) {
                        if(query_params.is_refresh || query_params.invalidatesCurrentBuffer()) {
                            //console.log('invalidates buffer!');
                            buffer_flushed = true;
                        }
                    }
                    
                    this.query_params = query_params.clone();
                    if(buffer_flushed) { 
                        //if buffer is invalidated, reset page
                        this.query_params.paginate_params.page = 0;
                        //console.log('is refresher');
                        const state_store_request: VocabulayStateStoreRequest | false = this.preFlushBuffer();
                        //if there is already a state store request, dont override it
                        if(!(this.query_params.state_store_request?.none || this.query_params.state_store_request?.all)) {
                            if(state_store_request) {
                                //console.log("added stateStoreReqeust");
                                this.query_params.addStateStoreRequest(state_store_request);
                            }
                        }
                    }
                    const buffered_page: BufferedPage | undefined = this.page_buffer.get(this.current_page);
                    if(!buffer_flushed && buffered_page) {
                        //console.log(`cache hit: ${this.current_page}`);
                        return of(buffered_page.vocabulary_states);
                    } else {
                        const page_request: VocabularyPageRequest = {
                            query_id: this.query_id,
                            query_params: this.query_params
                        };
                        //console.log(`requesting page: ${query_params.paginate_params.page}`);
                        return this.vocabulary_service.requestPage(page_request).pipe(
                            //we'll get here if a page was requested but not in the buffer
                            //this is either the first request, or the user jumped beyond the buffer 
                            switchMap((page_response: VocabularyPageResult) => {
                                if(this.hasQueryID() && this.query_id !== page_response.query_id && !buffer_flushed) {
                                    //query_id has changed
                                    this.query_id_change = true;
                                    //notify user that vocabulary has been updated (no user action is required)
                                    console.log('query_id changed!');
                                    this.message_dispatcher.pushNotification(new AppNotification(
                                        "Vocabulary updated from another device or tab",
                                        "toast",
                                        null, null,
                                        "vocabulary queryID changed"
                                    ));
                                }
                                this.query_id = page_response.query_id;
                                this.query_params.paginate_params.page = page_response.page;
                                this.total_vocabularies = page_response.total;
                                //this.taken = page_response.taken;
                                this.max_page = Math.max(Math.ceil(this.total_vocabularies / this.query_params.paginate_params.page_size) - 1, 0);
                                this.storeInBuffer(page_response);
                                return of(page_response.vocabulary_data);
                            })
                        );
                    }
                }),
                //respond to the result of the first request
                switchMap((vocabulary_list: Array<VocabularyState>) => {
                    this.page_results$.next({
                        query_id: this.query_id,
                        total: this.total_vocabularies,
                        page: this.current_page,
                        taken: vocabulary_list.length,
                        vocabulary_data: vocabulary_list
                    });
                    return of(true);
                }),
                delay(500), //give the table time to re-render
                switchMap((val: boolean) => {
                    this.calcNewBuffer();
                    //console.log('updated buffer');
                    const buffered_page_requests: Array<VocabularyQueryParams> = new Array<VocabularyQueryParams>();
                    //console.log(`pending_pages: ${this.pending_pages}`);
                    if(this.pending_pages.length > 0) {
                        for(let i=0; i<this.pending_pages.length; i++) {
                            const vqp: VocabularyQueryParams = this.query_params.clone();
                            vqp.pruneForBufferQuery();
                            vqp.setPage(this.pending_pages[i]);
                            if(i == 0 && this.query_id_change) {
                                const state_store_request: VocabulayStateStoreRequest | false = this.preFlushBuffer(); 
                                if(state_store_request) vqp.addStateStoreRequest(state_store_request);
                            }
                            buffered_page_requests.push(vqp);
                        }
                    }
                    return of(...buffered_page_requests);
                }),
                //buffer requests
                concatMap((buffered_page_request: VocabularyQueryParams) => {
                    //console.log(`buffer request: ${buffered_page_request.paginate_params.page}`);
                    //these requests will be for buffering pages. pagination will generate buffer requests
                    const page_request: VocabularyPageRequest = {
                        query_id: this.query_id,
                        query_params: buffered_page_request
                    };
                    return this.vocabulary_service.requestPage(page_request).pipe(
                        tap( (page_response: VocabularyPageResult) => {
                            //console.log(`received page: ${page_response.page}`);
                            //console.log(`requesting buffer: ${buffered_page_request.paginate_params.page}`);
                            if(this.query_id !== page_response.query_id) {
                                this.query_id = page_response.query_id;
                                this.queryIdChangedNotification();
                                //this.flushBuffer();
                                //console.log('query id changed!');
                                //throwError(new QueryIDChanged());
                            }
                            this.storeInBuffer(page_response);
                        })
                    );
                }),
                takeUntil(this.page_requests$), //take until next page_requests emission so the buffering operations get cancelled
                retry(1)
            ).subscribe({
                complete: () => {
                    this.storeBufferStates();
                    this.query_id_change = false;
                },
                error: (error) => {
                    console.log('ops! an error!');
                    console.log(error);
                }
            });
        });
    }

    public queryIdChangedNotification(): void {
        this.message_dispatcher.pushNotification(new AppNotification(
            "Vocabulary updated from another device or tab.",
            "sticky",
            {action_message: "Refresh", action_call: () => {
                if(this.owner) {
                    this.owner.refreshVocabulary(true);
                }
            }}, this.vocabulary_service.vocabulary_events$.pipe(
                filter( (vocabulary_event: VocabularyEvent) => {
                    if(!this.owner) return false;
                    if(compareComponents(vocabulary_event.owner, this.owner) && vocabulary_event.type == "refreshing") return true;
                    return false;
                }),
                mapTo(true)),
            "vocabulary queryID changed"
        ));
    }

    /*
    public testNotification(): void {
        console.log('test notification');
        this.message_dispatcher.pushNotification(new AppNotification(
            "Vocabulary updated from another device or tab. You should refresh the vocabulary list.",
            "sticky",
            {action_message: "Refresh", action_call: () => {
                console.log('owner:');
                console.log(this.owner);
                if(this.owner) {
                    this.owner.refreshVocabulary(true);
                }
            }}, this.vocabulary_service.vocabulary_events$.pipe(
                filter( (vocabulary_event: VocabularyEvent) => {
                    if(!this.owner) return false;
                    if(compareComponents(vocabulary_event.owner, this.owner)) return true;
                    return false;
                }),
                mapTo(true)),
            "vocabulary queryID changed"
        ));
    }
    */

    public preFlushBuffer(): VocabulayStateStoreRequest | false {
        //console.log('flushing buffer');
        const buffered_pages: Array<PageIndex> = [...this.page_buffer.keys()];
        const buffered_states: Array<VocabularySelectionState> = new Array<VocabularySelectionState>();
        for(let i = 0; i < buffered_pages.length; i++) {
            const buffered_page: BufferedPage | undefined = this.page_buffer.get(buffered_pages[i]);
            if(!buffered_page) continue;
            if(buffered_page.has_edited_selection && buffered_page.selection_state_status !== "saved") {
                for(let i=0; i<buffered_page.vocabulary_states.length; i++) {
                    buffered_states.push({
                        'entry_id': buffered_page.vocabulary_states[i].vocabulary.id,
                        'selected': buffered_page.vocabulary_states[i].selected
                    });
                }
                buffered_page.selection_state_status ="saving";
            }
            buffered_page.is_flushed = true;
        }
        if(buffered_states.length <= 0) return false;
        return {
            query_id: this.query_id,
            vocabulary_states: buffered_states
        };
    }

    public hasEditedSelection(page: number): void {
        const buffered_page: BufferedPage | undefined = this.page_buffer.get(page);
        if(buffered_page) {
            buffered_page.has_edited_selection = true;
        }
        this.has_edited_selection = true;
    }

    public queryWithBuffer(query_params: VocabularyQueryParams): void {
        //console.log('VocabularyQuery::queryWithBuffer');
        //console.log(query_params);
        this.page_requests$.next(query_params);
    }

    private storeInBuffer(page_response: VocabularyPageResult): void {
        //console.log(`storeInBuffer: ${page_response.page}`);
        //console.log(`buffering page ${missing_page}`);
        const new_page: BufferedPage = {
            vocabulary_states: page_response.vocabulary_data,
            selection_state_status: "unsaved",
            has_edited_selection: false,
            is_flushed: false
        };
        this.page_buffer.set(page_response.page, new_page);
        //console.log('storing in buffer:');
        //console.log(new_page);
        const pending_page_index: number = this.pending_pages.indexOf(page_response.page);
        if(pending_page_index >= 0) {
            this.pending_pages.splice(pending_page_index, 1);
        }
    }

    public storeVocabularySelectionState(store_request: VocabulayStateStoreRequest): Promise<boolean> {
        if(store_request.all) {
            const buffered_pages: Array<PageIndex> = [...this.page_buffer.keys()];
            for(let i = 0; i < buffered_pages.length; i++) {
                const buffered_page: BufferedPage | undefined = this.page_buffer.get(buffered_pages[i]);
                if(buffered_page) {
                    buffered_page.has_edited_selection = true;
                    buffered_page.selection_state_status = "unsaved";
                    for(let i=0; i<buffered_page.vocabulary_states.length; i++) {
                        buffered_page.vocabulary_states[i].selected = true;
                    }
                }
            }
        } else if(store_request.none) {
            const buffered_pages: Array<PageIndex> = [...this.page_buffer.keys()];
            for(let i = 0; i < buffered_pages.length; i++) {
                const buffered_page: BufferedPage | undefined = this.page_buffer.get(buffered_pages[i]);
                if(buffered_page) {
                    buffered_page.has_edited_selection = false;
                    buffered_page.selection_state_status = "unsaved";
                    for(let i=0; i<buffered_page.vocabulary_states.length; i++) {
                        buffered_page.vocabulary_states[i].selected = false;
                    }
                }
            }
        }
        return this.vocabulary_service.storeVocabState(store_request);
    }

    private storeBufferStates(): void {
        const buffered_pages: Array<PageIndex> = [...this.page_buffer.keys()];
        //console.log(`needed pages: ${needed_pages}`);
        //console.log(`buffered pages: ${buffered_pages}`);
        const vocabulary_state_store_requests: Array<{page: number, state_store: VocabulayStateStoreRequest}> = new Array<{page: number, state_store: VocabulayStateStoreRequest}>();
        for(let i = 0; i < buffered_pages.length; i++) {
            const buffered_page: BufferedPage | undefined = this.page_buffer.get(buffered_pages[i]);
            if(!buffered_page) return;

            const is_needed: boolean = this.needed_pages.indexOf(buffered_pages[i]) >= 0; 

            if(this.has_edited_selection) {
                if(buffered_page.has_edited_selection && buffered_page.selection_state_status !== "saved") {
                    const buffered_states: Array<VocabularySelectionState> = new Array<VocabularySelectionState>(buffered_page.vocabulary_states.length);
                    for(let i=0; i<buffered_page.vocabulary_states.length; i++) {
                        buffered_states[i] = {
                            entry_id: buffered_page.vocabulary_states[i].vocabulary.id,
                            selected: buffered_page.vocabulary_states[i].selected
                        }
                    }
                    //console.log(`saving: ${buffered_pages[i]}`);
                    buffered_page.selection_state_status ="saving";
                    vocabulary_state_store_requests.push({
                        page: buffered_pages[i],
                        state_store: {
                            query_id: this.query_id,
                            vocabulary_states: buffered_states
                        }
                    });
                } else {
                    if(!is_needed) { 
                        //console.log(`deleting ${buffered_pages[i]}`);
                        this.page_buffer.delete(buffered_pages[i]);
                    }
                }
            } else {
                if(!is_needed) { 
                    //console.log(`deleting ${buffered_pages[i]}`);
                    this.page_buffer.delete(buffered_pages[i]);
                }
            }
        }

        of(...vocabulary_state_store_requests).pipe(
            mergeMap((state_store_request: {page: number, state_store: VocabulayStateStoreRequest}) => {
                return from<Promise<boolean>>(
                    this.storeVocabularySelectionState({
                        query_id: state_store_request.state_store.query_id,
                        vocabulary_states: state_store_request.state_store.vocabulary_states
                    }).then(() => {
                        const buffered_page: BufferedPage | undefined = this.page_buffer.get(state_store_request.page);
                        if(buffered_page) {
                            const is_needed: boolean = this.needed_pages.indexOf(state_store_request.page) >= 0; 
                            if(!is_needed) {
                                //console.log(`deleting ${state_store_request.page}`);
                                this.page_buffer.delete(state_store_request.page);
                            }
                            else {
                                buffered_page.selection_state_status ="saved";
                            }
                        }
                        return true;
                        //console.log(`deleting page buffer: ${state_store_request.page}`);
                    }).catch((error) => {
                        //TODO what should we do here?
                        //maybe show this error message: 'some background operations failed to communicate with server. retry | refresh page'
                        console.log('could not save vocab state from buffer. keeping buffer data in memory.');
                        return false;
                    })
                );
            })
        ).subscribe({
            complete: () => {
                //console.log(`page_buffer: ${[...this.page_buffer.keys()]}`);
            }
        })
    }

    private calcNewBuffer(): void {
        this.needed_pages = new Array<PageIndex>();
        //right side
        let page_index: PageIndex = this.current_page;
        while(page_index <= this.max_page && (page_index - this.current_page) <= this.right_buffer_size) {
            this.needed_pages.push(page_index++);
        }
        //left side
        page_index = this.current_page - 1;
        while(page_index >= 0 && (this.current_page - page_index) <= this.left_buffer_size) {
            this.needed_pages.push(page_index--);
        }

        //console.log(`page_buffer: ${[...this.page_buffer.keys()]}`);
        //determine what pages will need to be requested
        this.pending_pages.length = 0;
        this.needed_pages.forEach( (needed_page: PageIndex) => {
            const buffered_page: BufferedPage | undefined = this.page_buffer.get(needed_page);
            if(!buffered_page || buffered_page.is_flushed) {
                //console.log(`buffering page ${needed_page}`);
                //console.log(buffered_page);
                this.pending_pages.push(needed_page);
            }
        });
    }
}
