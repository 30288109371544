import { AudioManager } from '@/services/AudioManager/AudioManager.inf';
import { Nullable } from '@/types';
import {injectable} from 'inversify';
import find from 'lodash/find';

@injectable()
export class AudioManagerImp implements AudioManager
{
    playAudio(): void {
        console.log('play audio');
    }
}
