
































import { Component, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import {Authenticator, MessageDispatcher, NavRouter} from '@/services';
import {lazyInject} from '@/ioc/inversify.config';
import {TYPES} from '@/ioc/types';
import { Nullable } from '@/types';
import remove from 'lodash/remove';
import { Route } from 'vue-router';
import {SHENGCI_MODULES} from '@/enums';
import { AppNotification } from '@/classes/AppNotification';
import {from, of, Observable, concat, Subject, throwError, Subscription, asyncScheduler } from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share, takeUntil, retry, tap, mergeMap, concatMap, debounceTime, throttleTime, mapTo, filter} from 'rxjs/operators';

/*
 * TODO: This component doesnt fully work. It does not accept multiple spams of notifications very gracefully. Need to redesign somehow
*/

@Component({
  components: {
    AsyncButton,
  }
})
export default class ToastsComponent extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.MESSAGEDISPATCHER_INSTANCE)
  private message_dispatcher!: MessageDispatcher;
  readonly max_toasts: number = 4;
  readonly timeout: number = 5000;
  public toasts: Array<AppNotification> = new Array<AppNotification>();
  public toast_queue: Array<AppNotification> = new Array<AppNotification>();

  created(): void {
    this.message_dispatcher.notifications$.pipe(
      filter( (notification: AppNotification) => notification.type == "toast")
      ).subscribe({
      next: (notification: AppNotification) => {
        if(this.toasts.length > this.max_toasts) {
          this.toast_queue.push(notification);
        } else {
          this.toasts.push(notification);
          setTimeout( () => {
            notification.is_open = true;
          }, 100);
          setTimeout( () => {
            this.closeToast(notification);
          }, this.timeout)
        }
      },
    });
  }

  closeToast(finished_notification: AppNotification): void {
    finished_notification.is_open = false;
    setTimeout( () => {
      remove(this.toasts, (notification: AppNotification) => {
        return notification.id == finished_notification.id;
      });
    }, 1500);
  }
}
