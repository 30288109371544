import {BrowserCookie} from '@/classes/BrowserCookie';
import {CookieMonster} from '@/services/CookieMonster/CookieMonster.inf';
import { Nullable } from '@/types';
import {COOKIE_NAMES} from '@/enums';
import {injectable} from 'inversify';
import find from 'lodash/find';

@injectable()
export class CookieMonsterImp implements CookieMonster
{
    getAllCokies(): BrowserCookie[] {
        const cookies = new Array<BrowserCookie>();
        document.cookie.split(';').forEach( (cookie_string: string) => {
            if(cookie_string.length > 0) {
                const cookie_info: string[] = [...cookie_string.split('=')];
                const cookie_name: string = cookie_info[0].trim(); 
                const cookie_value: string = decodeURIComponent(cookie_info[1].trim());
                cookies.push(new BrowserCookie(cookie_name, cookie_value, null));
            }
        });
        return cookies;
    }

    getCookie(cookie_name: COOKIE_NAMES): Nullable<BrowserCookie> {
        const cookies: BrowserCookie[] = this.getAllCokies();
        const cookie = find(cookies, (cookie: BrowserCookie) => {
            return cookie.name == cookie_name;
        });
        return cookie ? cookie : null;
    }
}
