import {ErrorObject} from "ajv";

export class InvalidAppConfiguration extends Error {
  constructor(message?: string) {
    super(message);
    this.name = `Invalid app environment configuration`;
    if(message && message.length > 0) {
      this.name = this.name.concat(`: ${message}`);
    }
  }
}

export class ModuleNotFound extends Error {
    constructor(message: string) {
      super(message);
      this.name = `Module Not Found ${message}`;
    }
}

export class NoAccessibleModule extends Error {
  constructor(message: string) {
    super(message);
    this.name = `This user cannot access any modules ${message}`;
  }
}

export class KeyDoesNotExist extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'Key does not exist';
  }
}

export class ValidationFailed extends Error {
  constructor(message_bag: ErrorObject<string, Record<string, any>, unknown>[]| null | undefined) {
    super("");
    console.log("Validation failed");
    message_bag?.forEach( (error_obj: ErrorObject<string, Record<string, any>, unknown>) => {
      console.log(error_obj);
    });
    this.name = 'Validation Failed';
  }
}

export class InvalidPageSize extends Error {
  constructor(message?: string) {
    super(message ?? '');
    this.name = 'Page size for pagination is larger than chunk size.';
  }
}

export class ChunkingError extends Error {
  constructor(message?: string) {
    super(message ?? '');
    this.name = 'An error ocurred while chunking';
  }
}

export class QueryIDChanged extends Error {
  constructor(message?: string) {
    super(message ?? '');
    this.name = 'The query ID has been changed.';
  }
}

export class FileResourceNotFound extends Error {
  constructor(message?: string) {
    super(message ?? '');
    this.name = 'The file resource was not found';
  }
}

export class QuizResumeResultError extends Error {
  constructor(message?: string) {
    super(message ?? '');
    this.name = 'Response did not contain a quiz_summary or entry.';
  }
}