import {inject, injectable} from 'inversify';
import {TYPES} from '@/ioc/types';
import {Observable, Subscription, from, timer, combineLatest, defer, throwError, Subject, ReplaySubject, of} from 'rxjs';
import {take, map, retryWhen, switchMap, catchError, share} from 'rxjs/operators';
import {Authenticator} from '@/services/Authenticator/Authenticator.inf';
import {Hanasu, ClientKeyedStorage, CookieMonster} from '@/services';
import {User} from '@/classes/User';
import {UserSettings,} from '@/classes/UserSettings';
import {Role, UserCredentials, UserInfo, AuthStatus, EmptyPostPayload, SessionStatus, Nullable} from '@/types';
import {AxiosResponse, AxiosError} from 'axios';
//import forIn from 'lodash/forIn';
import {genericRetryStrategy} from '@/helpers/genericRetryStrategy';
import {WAIT_TIMES, STORAGE_KEY_NAMES, WAIT_SCALING, COOKIE_NAMES} from '@/enums';
import {BrowserCookie} from '@/classes/BrowserCookie';

@injectable()
export class AuthenticatorImp implements Authenticator
{
    private readonly hanasu: Hanasu;
    private readonly client_storage: ClientKeyedStorage;
    private readonly cookie_monster: CookieMonster;
    public retry_attempts: number = 3;
    public readonly auth_events$: ReplaySubject<AuthStatus> = new ReplaySubject<AuthStatus>();
    public readonly session_events$: ReplaySubject<SessionStatus> = new ReplaySubject<SessionStatus>(1);
    private is_authenticated: boolean = false;
    public regenerating_session: boolean = false;
    public auth_status: AuthStatus = "pending";
    public session_status: SessionStatus = "pending";
    private local_user: Nullable<UserInfo> = null;

    private readonly logout_route: string = "/user/logout";
    private readonly login_route: string = "/user/login";
    private readonly csrf_token_route: string = "/sanctum/csrf-cookie";
    private readonly userinfo_route: string = "api/userinfo";

    public constructor(
        @inject(TYPES.HANASU_INSTANCE) _hanasu: Hanasu,
        @inject(TYPES.CLIENT_KEYEDSTORAGE_INSTANCE) _client_storage: ClientKeyedStorage,
        @inject(TYPES.COOKIE_MONSTER_INSTANCE) _cookie_monster: CookieMonster,
    ) {
        this.hanasu = _hanasu;
        this.client_storage = _client_storage;
        this.cookie_monster = _cookie_monster;
    }

    isAuthenticated(): boolean {
        return this.is_authenticated;
    }

    isRegeneratingSession(): boolean {
        return this.regenerating_session;
    }

    setSessionStatus(new_session_status: SessionStatus): void {
        this.session_status = new_session_status;
        this.session_events$.next(new_session_status);
    }

    setAuthStatus(new_auth_status: AuthStatus): void {
        this.auth_status = new_auth_status;
        this.auth_events$.next(new_auth_status);
        this.is_authenticated = this.auth_status == "logged in";
    }

    regenerateSession(): Promise<boolean> {
        //console.log('regenerating session');
        this.setSessionStatus("pending");
        this.setAuthStatus("pending");
        this.regenerating_session = true;
        return new Promise<boolean>( (resolve, reject) => {
            const user_info$: Subscription = this.getUserInfo$().subscribe({
                next: (user_info: AxiosResponse<UserInfo>) => {
                    this.setSessionStatus("valid");
                    this.loginUser({
                        user: User.fromJSON(user_info.data.user),
                        user_settings: UserSettings.fromJSON(user_info.data.user_settings)
                    });
                    this.regenerating_session = false;
                    user_info$.unsubscribe();
                    resolve(true);
                },
                error: (axios_error: AxiosError) => {
                    this.logoutUser();
                    //try making a new session
                    this.getCSRFToken$().subscribe({
                        next: () => {
                            console.log('got token!');
                            this.setSessionStatus("valid");
                            this.regenerating_session = false;
                            resolve(true);
                            user_info$.unsubscribe();
                        },
                        error: () => {
                            console.log('couldnt get token!');
                            this.setSessionStatus("invalid");
                            this.regenerating_session = false;
                            reject(axios_error);
                            user_info$.unsubscribe();
                        }
                    });
                }
            });
            /*
            this.requestUserInfo().then( (user_info: UserInfo) => {
                this.setSessionStatus("valid");
                this.loginUser(user_info);
                resolve(true);
            }).catch( (err) => {
                this.requestNewSession().then(()=>{
                    this.setSessionStatus("valid");
                    resolve(true);
                }).catch(()=>{
                    console.log('could not create new session');
                    this.setSessionStatus("invalid");
                    //reject(false);
                });
                this.logoutUser();
            }).finally( () => {
                this.regenerating_session = false;
            });
            */
        }); 
    }

    private getCSRFToken$(): Observable<AxiosResponse<any>> {
        return this.hanasu.getBuffered(this.csrf_token_route, undefined, WAIT_TIMES.SLOWER);
    }

    /*
    requestNewSession(): Promise<boolean> {
        return new Promise<boolean>( (resolve, reject) => {
            from(this.hanasu.get(this.csrf_token_route)).pipe(retryWhen(genericRetryStrategy({
                scalingDuration: WAIT_SCALING.INFREQUENT,
                excludedStatusCodes: [422]
              })))
              .subscribe({
                next: () => {
                    resolve(true);
                },
                error: (errObj) => {
                    console.log('could not fetch session token');
                    console.log(errObj);
                    reject(false);
                }
            });
        });
    }
    */

    /*
    requestUserInfo(): Promise<UserInfo> {
        return new Promise<UserInfo>( (resolve, reject) => {
            const user_response_sub$ = this.fetchUserInfo().subscribe( {
                next: (auth_result: AxiosResponse<UserInfo>) => {
                    resolve(auth_result.data as UserInfo);
                },
                error: (axios_error: AxiosError) => {
                    reject(false);
                },
                complete: () => {
                    user_response_sub$.unsubscribe();
                }
            });
        });
    }
    */

    /*
    private fetchUserInfo(): Observable<AxiosResponse<UserInfo>> {
        const start_time = new Date().getTime();
        const delay_timer$ = timer(WAIT_TIMES.SLOW).pipe(take(1), share());
        const user_response$ = from( defer( () => this.hanasu.post<EmptyPostPayload, UserInfo>(this.userinfo_route)) ).pipe(
            retryWhen(genericRetryStrategy({
                scalingDuration: WAIT_SCALING.INFREQUENT,
                excludedStatusCodes: [419, 422, 401]
              })),
              catchError(error => {
                const remaining_time = (start_time + WAIT_TIMES.SLOWER) - (new Date().getTime());
                if(remaining_time <= 0) {
                    return throwError(error);
                } else {
                    return timer(remaining_time).pipe(take(1), switchMap(() => throwError(error)));
                }
              })
        );

        const buffered_response$ = combineLatest(delay_timer$, user_response$);

        return buffered_response$.pipe(map( (value) => {
            return value[1] as AxiosResponse<UserInfo>;
        }));
    }
    */

    private getUserInfo$(): Observable<AxiosResponse<UserInfo>> {
        return this.hanasu.postBuffered<any, UserInfo>(this.userinfo_route, {}, undefined, WAIT_TIMES.SLOWER);
    }
    
    setLocalUserInfo(user_info: UserInfo): void {
        //this.client_storage.setItem(STORAGE_KEY_NAMES.USER_KEY, user.asString());
        this.local_user = user_info;
    }

    cleanseLocalUser(cleanse_user_settings: boolean = false) {
        //if(this.client_storage.hasItem(STORAGE_KEY_NAMES.USER_KEY)) {
        //    this.client_storage.removeItem(STORAGE_KEY_NAMES.USER_KEY);
        //}
        this.local_user = null;
    }

    getLocalUserInfo(): Nullable<UserInfo> {
        return this.local_user;
    }

    logoutUser() : void {
        this.cleanseLocalUser();
        this.setAuthStatus("logged out");
    }

    loginUser(user_info: UserInfo): void {
        this.setLocalUserInfo(user_info);
        this.setAuthStatus("logged in");
    }

    tryLogin(auth_request: UserCredentials): Promise<boolean> {
        return new Promise<boolean>( (resolve, reject) => {
            const auth_subscription$: Subscription = this.authenticate$(auth_request).subscribe({
                next: (auth_result: AxiosResponse<UserInfo>) => {
                    this.loginUser({
                        user: User.fromJSON(auth_result.data.user),
                        user_settings: UserSettings.fromJSON(auth_result.data.user_settings)
                    });
                    auth_subscription$.unsubscribe();
                    resolve(true);
                },
                error: (axios_error: AxiosError) => {
                    auth_subscription$.unsubscribe();
                    reject(axios_error);
                },
              });
        });
    }

    private authenticate$(auth_request: UserCredentials): Observable<AxiosResponse<UserInfo>> {
        return this.hanasu.postBuffered<UserCredentials, UserInfo>(this.login_route, auth_request, undefined, WAIT_TIMES.SLOWER);
    }

    tryLogout(): Promise<boolean> {
        return new Promise<boolean>( (resolve, reject) => {
            const auth_subscription$: Subscription = this.disauthenticate$().subscribe({
                next: (auth_result: AxiosResponse<UserInfo>) => {
                    this.logoutUser();
                    auth_subscription$.unsubscribe();
                    resolve(true);
                },
                error: (axios_error: AxiosError) => {
                    auth_subscription$.unsubscribe();
                    reject(axios_error);
                },
            });
        });
    }

    private disauthenticate$(): Observable<AxiosResponse<UserInfo>> {
        return this.hanasu.postBuffered<any, UserInfo>(this.logout_route, {}, undefined, WAIT_TIMES.SLOWER);
    }

    /*
    private disauthenticate(): Observable<AxiosResponse<UserInfo>> {
        const start_time = new Date().getTime();
        const delay_timer$ = timer(WAIT_TIMES.SLOW).pipe(take(1), share());
        const auth_response$ = from( defer( () => this.hanasu.post(this.logout_route)) ).pipe(
            retryWhen(genericRetryStrategy({
                scalingDuration: WAIT_SCALING.INFREQUENT,
                excludedStatusCodes: [422]
              })),
              catchError(error => {
                const remaining_time = (start_time + WAIT_TIMES.SLOW) - (new Date().getTime());
                if(remaining_time <= 0) {
                    return throwError(error);
                } else {
                    return timer(remaining_time).pipe(take(1), switchMap(() => throwError(error)));
                }
              })
        );
        
        const buffered_response$ = combineLatest(delay_timer$, auth_response$);

        return buffered_response$.pipe(map( (value) => {
            return value[1] as AxiosResponse<UserInfo>;
        }));
    }
    */

    /*
    private authenticate(auth_request: UserCredentials): Observable<AxiosResponse<UserInfo>> {
        const start_time = new Date().getTime();
        const delay_timer$ = timer(WAIT_TIMES.SLOWER).pipe(take(1), share());
        const auth_response$ = from( defer( () => this.hanasu.post<UserCredentials,UserInfo>(this.login_route, auth_request)) ).pipe(
            retryWhen(genericRetryStrategy({
                scalingDuration: WAIT_SCALING.INFREQUENT,
                excludedStatusCodes: [422]
              })),
              catchError(error => {
                const remaining_time = (start_time + WAIT_TIMES.SLOWER) - (new Date().getTime());
                if(remaining_time <= 0) {
                    return throwError(error);
                } else {
                    return timer(remaining_time).pipe(take(1), switchMap(() => throwError(error)));
                }
              })
        );
        
        const buffered_response$ = combineLatest(delay_timer$, auth_response$);

        return buffered_response$.pipe(map( (value) => {
            return value[1] as AxiosResponse<UserInfo>;
        }));
    }
    */
}