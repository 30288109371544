import { Container } from 'inversify';
import { TYPES } from '@/ioc/types';
import { Hanasu, HanasuImp } from '@/services';
import { Authenticator, AuthenticatorImp } from '@/services';
import { ModuleProvider, ModuleProviderImp } from '@/services';
import { ClientKeyedStorage, ClientKeyedStorageImp } from '@/services';
import { CookieMonster, CookieMonsterImp } from '@/services';
import { MessageDispatcher, MessageDispatcherImp } from '@/services';
import { NavRouter, NavRouterImp } from '@/services';
import { TagBoss, TagBossImp } from '@/services';
import { VocabularyService, VocabularyServiceImp } from '@/services';
import { AudioManager, AudioManagerImp } from '@/services';
import { FileResourceService, FileResourceServiceImp } from '@/services';
import { LiebiaoManager, LiebiaoManagerImp } from '@/services';
import { QuizService, QuizServiceImp } from '@/services';
import getDecorators from 'inversify-inject-decorators';

const container = new Container();

// configuration of our container
container.bind<Hanasu>(TYPES.HANASU_INSTANCE).to(HanasuImp).inSingletonScope();
container.bind<Authenticator>(TYPES.AUTHENTICATOR_INSTANCE).to(AuthenticatorImp).inSingletonScope();
container.bind<ModuleProvider>(TYPES.MODULE_PROVIDER_INSTANCE).to(ModuleProviderImp).inSingletonScope();
container.bind<ClientKeyedStorage>(TYPES.CLIENT_KEYEDSTORAGE_INSTANCE).to(ClientKeyedStorageImp).inSingletonScope();
container.bind<CookieMonster>(TYPES.COOKIE_MONSTER_INSTANCE).to(CookieMonsterImp).inSingletonScope();
container.bind<MessageDispatcher>(TYPES.MESSAGEDISPATCHER_INSTANCE).to(MessageDispatcherImp).inSingletonScope();
container.bind<NavRouter>(TYPES.NAVROUTER_INSTANCE).to(NavRouterImp).inSingletonScope();
container.bind<TagBoss>(TYPES.TAGBOSS_INSTANCE).to(TagBossImp).inSingletonScope();
container.bind<VocabularyService>(TYPES.VOCABULARYSERVICE_INSTANCE).to(VocabularyServiceImp).inSingletonScope();
container.bind<AudioManager>(TYPES.AUDIOMANAGER_INSTANCE).to(AudioManagerImp).inSingletonScope();
container.bind<FileResourceService>(TYPES.FILE_RESOURCE_SERVICE_INSTANCE).to(FileResourceServiceImp).inSingletonScope();
container.bind<LiebiaoManager>(TYPES.LIEBIAO_MANAGER_INSTANCE).to(LiebiaoManagerImp).inSingletonScope();
container.bind<QuizService>(TYPES.QUIZ_SERVICE_INSTANCE).to(QuizServiceImp).inSingletonScope();

export default container;
export const { lazyInject } = getDecorators(container);
