import {Stringable, Role, Nullable, Validatable} from '@/types';
//import {FunctionParameterBadType, FunctionParameterEmpty, FunctionParameterBadValue} from '@/exceptions';
import Ajv, {JSONSchemaType} from "ajv";
import addFormats from "ajv-formats";
import {ValidationFailed} from '@/exceptions';

export interface UserSettingsParams {
    theme: string;
    font: string;
    furigana: string;
    updated_at: string;
}

export const user_settings_params_schema: JSONSchemaType<UserSettingsParams> = {
    type: "object",
    properties: {
        theme: {type: "string"},
        font: {type: "string"},
        furigana: {type: "string"},
        updated_at: {type: "string", format: "date-time"},
    },
    required: ["theme", "font", "furigana", "updated_at"],
    additionalProperties: false
}

const ajv = new Ajv();
addFormats(ajv);

export const user_settings_params_validator = ajv.compile<UserSettingsParams>(user_settings_params_schema)

export class UserSettings implements Stringable, Validatable
{
    theme: string = "some_theme";
    font: string = "some_font";
    furigana: string = "some_font";
    updated_at: Date = new Date(Date.now());

    constructor(theme: string, font: string, furigana: string, updated_at: Date) {
        this.theme = theme; 
        this.font = font;
        this.furigana = furigana; 
        this.updated_at = updated_at;
    }

    static fromJSON(user_settings_params: UserSettingsParams): UserSettings {
        if(!user_settings_params_validator(user_settings_params)) {
            throw new ValidationFailed(user_settings_params_validator.errors);
        }
        return new UserSettings(user_settings_params.theme, user_settings_params.font, user_settings_params.furigana, new Date(user_settings_params.updated_at));
    }

    asString(): string {
        return JSON.stringify(this);
    }
}